<template>
  <div v-if="options.vertical" class="field vs-input-select-vertical">
    <label class="label">{{ label }}</label>
    <vs-select v-model="value" :options="options"></vs-select>
  </div>
  <div v-else class="field is-horizontal vs-input-select">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div :class="{ 'is-grouped': options.batch }" class="field">
        <div v-if="options.batch" class="control">
          <div class="select">
            <select v-model="batched" @change="$emit('batching', batched)">
              <option value="">{{ $i18n.translate('scaffold', 'batch.ignore', 'Ignore') }}</option>
              <option value="update">{{ $i18n.translate('scaffold', 'batch.update', 'Update') }}</option>
              <option v-if="!options.required" value="nullify">{{ $i18n.translate('scaffold', 'batch.nullify', 'Nullify') }}</option>
            </select>
          </div>
        </div>
        <div class="control is-expanded">
          <div :class="options.icon ? 'has-icons-left' : ''" class="control">
            <vs-select @input="input" @validation="validation" :value="value" :options="options" :disabled="options.disabled || (options.batch && batched != 'update')"></vs-select>
          </div>
        </div>
        <div :class="{'has-text-danger': options.error}" class="help vs-description">{{ options.error || options.description }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import VsSelect from '../VsSelect.vue'
  export default {
    components: {
      'vs-select': VsSelect
    },
    props: [ 'container', 'column', 'label', 'options', 'value' ],
    data: function () {
      return {
        batched: '',
      }
    },
    mounted: function() {
      if (this.options.autofocus) this.$refs.input.focus();
    },
    computed: {
      css_class: function() {
        return 'input ' + (this.validated || this.options.disabled ? 'is-success' : 'is-danger');
      },
      selection_label: function() {
        if (this.options.select) return this.options.select[this.value];
        return this.value;
      },
      validated: function() {
        var validating = false;
        return validating;
      }
    },
    methods: {
      blur: function(e) {
        this.$emit('blur', e);
      },
      enter: function(e) {
        this.$emit('enter', e)
      },
      focus: function() {
        this.$refs.input.focus();
      },
      input: function(e) {
        this.$emit('input', e);
      },
      validation: function(e) {
        this.$emit('validation', e);
      }
    }
  }
</script>
