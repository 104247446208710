<template>
  <div v-if="visible" class="vs-dashboard">
    <div class="vs-dashboard-side">
      <fieldset>
        <legend>Version</legend>
        <div class="vs-dashboard-panel">
          <table>
            <tr>
              <td>Branch:</td>
              <td v-if="data">{{ data.version.branch }}</td>
            </tr>
            <tr>
              <td>Commit:</td>
              <td><div v-if="data" v-html="data.version.commit"></div></td>
            </tr>
            <tr>
              <td>Memory:</td>
              <td v-if="data">{{ data.version.memory }}</td>
            </tr>
            <tr>
              <td>Threads:</td>
              <td v-if="data">{{ data.version.threads }}</td>
            </tr>
          </table>
        </div>
      </fieldset>
      <fieldset>
        <legend>Service Information</legend>
        <div class="vs-dashboard-panel">
          <table>
            <tr>
              <td>OpenDKIM:</td>
              <td v-if="data">{{ data.services.opendkim }}</td>
            </tr>
            <tr>
              <td>Postfix:</td>
              <td v-if="data">{{ data.services.postfix }}</td>
            </tr>
            <tr>
              <td>PostgreSQL:</td>
              <td v-if="data">{{ data.services.postgresql }}</td>
            </tr>
            <tr>
              <td>Nginx:</td>
              <td v-if="data">{{ data.services.nginx }}</td>
            </tr>
            <tr>
              <td>Redis:</td>
              <td v-if="data">{{ data.services.redis }}</td>
            </tr>
            <tr>
              <td>SpamAssassin:</td>
              <td v-if="data">{{ data.services.spamassassin }}</td>
            </tr>
          </table>
       </div>
      </fieldset>
      <fieldset>
        <legend>Background Job Information</legend>
        <div class="vs-dashboard-panel">
          <table>
            <tr>
              <td>Processed: </td>
              <td v-if="data">{{ data.sidekiq.processed }}</td>
            </tr>
            <tr>
              <td>Failed:</td>
              <td v-if="data">{{ data.sidekiq.failed }}</td>
            </tr>
            <tr>
              <td>Retry:</td>
              <td v-if="data">{{ data.sidekiq.retry_size }}</td>
            </tr>
            <tr>
              <td>Dead:</td>
              <td v-if="data">{{ data.sidekiq.dead_size }}</td>
            </tr>
            <tr>
              <td>Workers running: </td>
              <td v-if="data">{{ data.sidekiq.workers_size }}</td>
            </tr>
          </table>
        </div>
      </fieldset>
      <fieldset>
        <legend>Database Information</legend>
        <div class="vs-dashboard-panel vs-dashboard-panel-db">
          <table v-if="data">
            <tr v-for="item in data.db" :key="item.label">
              <td>{{ item.label }}</td>
              <td>{{ item.size }}</td>
            </tr>
          </table>
        </div>
      </fieldset>
    </div>
    <div class="vs-dashboard-content">
      <fieldset>
        <legend>Statistics</legend>
        <div style="overflow:hidden">
          <iframe style="width:100%;height:100%;border:0" :src="'/' + $i18n.locale + '/?stat&t=' + new Date().getTime()"></iframe>
        </div>
      </fieldset>
      <fieldset>
        <legend>Recent activities</legend>
        <vs-scaffold :notitle="true" :nocontrols="true" :data="{ url: '/' + $i18n.locale + '/audits' }"></vs-scaffold>
      </fieldset>
    </div>
  </div>
</template>
<script>
  import VsScaffold from './VsScaffold.vue'
  export default {
    components: {
      'vs-scaffold': VsScaffold
    },
    props: {
      visible: {
        type: Boolean,
        default: function () { return false }
      }
    },
    data: function () {
      return {
        data: null
      }
    },
    created: function() {
      this.$http.interceptors.response.use((response) => {
        if (response.data['_dashboard_']) {
          this.data = response.data['_dashboard_'];
          this.$emit('loaded', 'dashboard');
        }
        return response;
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
</script>
<style>
.vs-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
}
.vs-dashboard-side { width: 170px; display: flex; flex-direction: column; font-size: 0.8em }
.vs-dashboard-content { flex: 1; display:flex; flex-direction:column; font-size: 0.8em }
.vs-dashboard-side fieldset { border:1px solid #ccc;margin:5px; padding:0 5px; overflow:hidden}
.vs-dashboard-side fieldset:last-child {flex:1}
.vs-dashboard-content fieldset { flex:1; border:1px solid #ccc;margin:5px; padding:0 5px; overflow:hidden}
.vs-dashboard-content fieldset > div { height:calc(100% - 20px); overflow:auto}
.vs-dashboard-content pre { padding: 0.25rem; white-space: pre-wrap; background-color:transparent }
.vs-dashboard-side fieldset > div { width: 150px }
.vs-dashboard fieldset {border-radius:5px}
.vs-dashboard-panel table { width: 100% }
.vs-dashboard-panel td { vertical-align: top !important; padding: 1px }
.vs-dashboard-panel-db {font-size:0.9em; height:calc(100% - 20px); overflow: auto}
@supports (-moz-appearance:none) {
  .vs-dashboard-panel-db { height:calc(100% - 5px) }
  .vs-dashboard-content fieldset > div { height:calc(100% - 5px); overflow:auto}
}
h5.vs-dashboard-heading {
  display: flex;
  flex-direction: row;
}
h5.vs-dashboard-heading:before, h5.vs-dashboard-heading:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #ccc;
  margin: auto;
}
</style>
