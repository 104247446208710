<template>
  <div class="field vs-input-otp">
    <input style="margin-right: 0.5em" :checked="value" @input="input" @keyup.enter="enter" ref="input" type="checkbox">
    <label style="display:inline" class="label">{{ label }}</label>
    <div class="control">
      <img :src="q" style="margin:1em 0 0 1em">
    </div>
  </div>
</template>
<script>
  import QRious from 'qrious'
  export default {
    props: [ 'container', 'column', 'label', 'options', 'value' ],
    data: function () {
      return {
        enabled: false,
        q: '',
      }
    },
    mounted: function() {
      var q = new QRious({ value: 'otpauth://totp/' + this.options.login + '?secret=' + this.options.token + '&issuer=' + encodeURIComponent(window.location.hostname) });
      this.q = q.toDataURL();
      this.enabled = this.value || false;
    },
    methods: {
      enter: function(e) {
        this.$emit('enter', e)
      },
      focus: function() {
        this.$refs.input.focus();
      },
      input: function(e) {
        this.$emit('input', e.target.checked);
      },
    }
  }
</script>
