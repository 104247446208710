<template>
  <div class="vs-navbar-item" :class="data.items ? 'vs-navbar-item-nesting' : ''">
    <a :disabled="!data.enabled" class="vs-navbar-link" @click.prevent.stop="$emit('navigate', data, $event)">
      <span v-if="data.icon" class="icon"><i class="fas" :class="data.icon"></i></span>
      <span>{{ data.label }}</span>
    </a>
    <div v-if="data.items" class="vs-navbar-group">
      <vs-navbar-item @navigate="(data, event) => $emit('navigate', data, event)" v-for="(item,index) in data.items" :data="item" :key="'item_' + index"></vs-navbar-item>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'vs-navbar-item',
    props: {
      data: {
        type: Object,
        default: function () { return {} }
      }
    }
  }
</script>
<style>
@media screen and (min-width: 768px) {
  .vs-navbar-group {
    background: linear-gradient(to bottom, #1B6C97, #2183B8);
    box-shadow: 0px 2px 4px #666666;
    display: none;
    margin-top: 2.5em;
    position: absolute;
  }
  .vs-navbar-start .vs-navbar-group {
    min-width: 10em;
  }
  .vs-navbar-group > .vs-navbar-item:not(:last-child) {
    border-bottom: 1px #1b6c97 solid;
  }
  .vs-navbar-item {
    display: flex;
    white-space: nowrap;
  }
  .vs-navbar-end > .vs-navbar-item {
    position: relative;
  }
  .vs-navbar-item:hover > .vs-navbar-group {
    display: block;
  }
  .vs-navbar-link {
    flex: 1 1;
    padding: 0.5em;
    position: relative;
    color:#fefefe;
    text-shadow: 1px 1px 3px #000;
  }
  .vs-navbar-start .vs-navbar-group .vs-navbar-link {
    padding: 0.5em 1.5em 0.5em 0.5em;
  }
  .vs-navbar-end .vs-navbar-link {
    display: flex;
    justify-content: flex-end;
  }
  .vs-navbar-link:hover {
    color: #FFE3B1;
  }
  .vs-navbar-group .vs-navbar-link:hover {
    background: #638596;
  }
  .vs-navbar-start .vs-navbar-item-nesting .vs-navbar-item-nesting > a:after {
    content: '';
  	position: absolute;
    right: 0.5em;
    top: 1em;
  	width: 0;
  	height: 0;
  	border-left: 0.3em #fefefe solid;
  	border-top: 0.3em dashed transparent;
  	border-right: none;
  	border-bottom: 0.3em dashed transparent;
  }
  .vs-navbar-end .vs-navbar-item-nesting .vs-navbar-item-nesting > a:after {
    content: '';
  	position: absolute;
    left: 0.5em;
    top: 1em;
  	width: 0;
  	height: 0;
  	border-right: 0.3em #fefefe solid;
  	border-top: 0.3em dashed transparent;
  	border-left: none;
  	border-bottom: 0.3em dashed transparent;
  }
  .vs-navbar-start .vs-navbar-group .vs-navbar-group {
    margin-top: 0;
    margin-left: 100%;
  }
  .vs-navbar-end .vs-navbar-group {
    right: 0;
  }
  .vs-navbar-end .vs-navbar-group .vs-navbar-group {
    right: unset;
    margin-top: 0;
    margin-left: -100%;
  }
}
@media screen and (max-width: 768px) {

}
</style>
