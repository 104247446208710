<template>
  <div id="app" @mousedown="disable_double_click" ref="app">
    <vs-navbar @help="help.visible=true" @navigate="navigate"></vs-navbar>
    <div id="mce-toolbar"></div>
    <div class="vs-main">
      <vs-authentication></vs-authentication>
      <vs-dashboard @loaded="loaded" :visible="view == 'dashboard'" ref="dashboard"></vs-dashboard>
      <vs-scaffold @loaded="loaded" :visible="view == 'scaffold'" :data="scaffold" ref="scaffold"></vs-scaffold>
      <vs-indicator></vs-indicator>
    </div>
    <vs-help v-if="$i18n.locale" @close="help.visible=false" :visible="help.visible"></vs-help>
    <vs-dialog v-if="dialog.visible" :data="dialog" @close="dialog.visible = false"></vs-dialog>
    <vs-status v-if="status.timestamp" :status="status"></vs-status>
    <div class="vs-background"></div>
  </div>
</template>
<script>
  import VsAuthentication from './components/scaffold/VsAuthentication.vue'
  import VsDialog from './components/scaffold/VsDialog.vue'
  import VsHelp from './components/scaffold/VsHelp.vue'
  import VsIndicator from './components/scaffold/VsIndicator.vue'
  import VsDashboard from './components/scaffold/VsDashboard.vue'
  import VsNavbar from './components/scaffold/VsNavbar.vue'
  import VsScaffold from './components/scaffold/VsScaffold.vue'
  import VsStatus from './components/scaffold/VsStatus.vue'
  export default {
    components: {
      'vs-authentication': VsAuthentication,
      'vs-dialog': VsDialog,
      'vs-help': VsHelp,
      'vs-indicator': VsIndicator,
      'vs-dashboard': VsDashboard,
      'vs-navbar': VsNavbar,
      'vs-scaffold': VsScaffold,
      'vs-status': VsStatus
    },
    created: function() {
      window.onpopstate = (e) => {
        this.scaffold = { url: window.location.pathname };
      };
      window.onresize = (e) => {
        this.screen.width = window.innerWidth;
        this.screen.height = window.innerHeight;
      };
      this.screen.width = window.innerWidth;
      this.screen.height = window.innerHeight;
      this.$util.shared.screen = this.screen;
      this.$dialog.data = this.dialog;
      // this.$cable.connect();
      this.$http.interceptors.request.use((config) => {
        if (config.method == 'get' && !('params' in config)) config.params = {};
        if (config.params) config.params['_xhr_'] = new Date().getTime();        
        if (this.$i18n.data.cache_key) config.headers['vs-i18n-cache-key'] = this.$i18n.data.cache_key;
        config.headers['vs-xhr'] = new Date().getTime();
        return config;
      }, (error) => {
        console.log('request error: ', error, error.request);
        return Promise.reject(error);
      });
      this.$http.interceptors.response.use((response) => {
        if (response.headers['vs-env'] == 'development') console.log('response: ', response.data, response.headers);
        if (response.headers['vs-csrf-token']) this.$http.defaults.headers.common['X-CSRF-Token'] = response.headers['vs-csrf-token'];
        if (response.headers['vs-locale']) this.$i18n.locale = response.headers['vs-locale'];
        if (response.headers['vs-status']) this.status.set(true, response.headers['vs-status'], response.headers['vs-user'], new Date() - parseInt(response.headers['vs-xhr']));
        // dialog
        if (response.data['_dialog_']) {
          this.$dialog.show(response.data['_dialog_']).then((result) => {
            this.dialog.visible = false;
          }).catch((result) => {
            this.dialog.visible = false;
          });
        }
        // message dialog
        if (response.data['_message_']) {
          this.$dialog.alert(response.data._message_.label, response.data._message_.text, response.data._message_.css_class);
        }
        if (response.data['_i18n_']) Object.assign(this.$i18n.data, response.data['_i18n_']);
        if (response.data['_reload_']) location.reload();
        return response;
      }, (error) => {
        if (error.response.headers['vs-env'] == 'development') console.log('response error: ', error.response);
        if (error.response.headers['vs-csrf-token']) this.$http.defaults.headers.common['X-CSRF-Token'] = error.response.headers['vs-csrf-token'];
        if (error.response.headers['vs-locale']) this.$i18n.locale = error.response.headers['vs-locale'];
        if (error.response.headers['vs-status']) this.status.set(false, error.response.headers['vs-status'], error.response.headers['vs-user'], new Date() - parseInt(error.response.headers['vs-xhr']));
        if (error.response.data['_i18n_']) Object.assign(this.$i18n.data, error.response.data['_i18n_']);
        return Promise.reject(error);
      });
      this.scaffold = { url: window.location.href };
    },
    data: function () {
      return {
        view: null,
        help: {
          visible: false
        },
        scaffold: {},
        screen: {
          width: 0,
          height: 0
        },
        status: {
          set: function(success, message, user, latency) {
            if (this.message) this.history.push({ success: this.success, user: this.user, message: this.message, timestamp: this.timestamp, latency: this.latency });
            this.success = success;
            this.message = message;
            this.user = user;
            this.latency = latency;
            this.timestamp = new Date();
          },
          history: [],
          user: null,
          message: null,
          success: null,
          timestamp: null,
          latency: null
        },
        dialog: {
          css_class: null,
          components: null,
          label: null,
          reject: null,
          resolve: null,
          text: null,
          visible: false
        }
      }
    },
    methods: {
      disable_double_click: function(e) {
        // this is to prevent 'select all' with Chrome on double clicks
        if (e.detail > 1) e.preventDefault();
      },
      loaded: function(value) {
        this.view = value;
      },
      navigate: function(data, e) {
        if (!data.url || data.url == '') return;
        if (data.display == 0) {
          this.$http.indicator = e;
          this.$http({ url: data.url, method: data.method || 'GET' }).then((response) => {
            if (data.nohistory) return;
            history.pushState(data.label, data.label, data.url);
            if (response.data._scaffold_) {
              this.scaffold = { url: data.url, prefetch: { scaffold: response.data._scaffold_, execjs: response.data._execjs_ } };
              this.view = 'scaffold';
            }
          }).catch((error) => {});
        } else if (data.display == 1) {
          // render as popup dialog
        } else if (data.display == 2) window.open(data.url, '_blank');
      }
    }
  }
</script>
<style>
  #app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .vs-main {
    flex: 1 1;
    font-size: 0.9em;
    overflow-y: auto;
  }
  .vs-background {
    background-image: url('./images/wheel-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
    position: fixed;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
    pointer-events: none;
    opacity: 0.2;
    z-index: 5;
  }
</style>
