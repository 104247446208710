<template>
  <div>{{ output }}</div>
</template>
<script>
  export default {
    props: [ 'data', 'options' ],
    computed: {
      output: function() {
        return (this.options && this.options.precision) ? parseFloat(Math.round(this.data.value * 100) / 100).toFixed(this.options.precision) : this.data.value;
      }
    }
  }
</script>
