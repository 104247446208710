<template>
  <div class="vs-status">
    <div v-if="show_history" class="vs-status-history" :style="{ display: history_visible ? 'block' : 'none' }" ref="history">
      <table>
        <tr v-for="history in status.history">
          <td class="vs-status-timestamp">{{ history.timestamp.toLocaleTimeString() }}</td>
          <td class="vs-status-user">{{ history.user }}</td>
          <td class="vs-status-message"><span :title="history.detail" :style="{ color: (history.success ? 'green' : 'red') }">{{ history.message }}</span></td>
          <td class="vs-status-latency">{{ history.latency }} ms</td>
        </tr>
      </table>
    </div>
    <table>
      <tr>
        <td class="vs-status-timestamp">{{ status.timestamp.toLocaleTimeString() }}</td>
        <td class="vs-status-user">{{ status.user }}</td>
        <td class="vs-status-message"><span :title="status.detail" :style="{ color: (status.success ? 'green' : 'red') }">{{ status.message }}</span></td>
        <td class="vs-status-latency">{{ status.latency }} ms</td>
      </tr>
    </table>
    <a :style="{ cursor: status.history.length > 0 ? 'pointer' : 'default' }" :disabled="status.history.length == 0" @click="history_visible = !history_visible"><i class="fas fa-ellipsis-v"></i></a>
  </div>
</template>
<script>
  export default {
    name: 'vs-status',
    props: {
      status: {
        type: Object,
        default: function () { return {} }
      },
      show_history: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        history_visible: false
      }
    },
    updated: function () {
      this.$nextTick(() => {
        if (this.history_visible) this.$refs.history.scrollTop = this.$refs.history.scrollHeight;
      })
    },
    methods: {
    }
  }
</script>
<style>
.vs-status {
  background-color: #eee;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  z-index: 10;
  font-size: 0.75rem;
}
.vs-status > a {
  padding: 0.25rem 6px;
  border-left: 1px solid #ccc;
}
.vs-status > table {
  flex: 1 1;
}
.vs-status-history {
  background-color: #fefefe;
  border-top: 2px solid #ccc;
  bottom: 2em;
  max-height: 30vh;
  overflow-y: auto;
  position: absolute;
  width: 100%;
}
.vs-status-history td {
  border-bottom: 1px solid #ccc;
}
.vs-status-history .vs-status {
  background-color: transparent;
}
.vs-status-timestamp,
.vs-status-user {
  border-right: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
  width: 1%;
  white-space: nowrap;
}
.vs-status-message {
  cursor: default;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vs-status-message span[tooltip]:before {
  content : attr(tooltip);
  display: none;
  position : absolute;
  transition: all 0.15s ease;
  padding: 0.5rem;
  background-color: #f0e68c;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -2.5rem;
  max-width: 80%;
  margin-left: 5rem;
  color: #333;
  border-radius: 4px;
  box-shadow: 2px 2px 2px silver;
}
.vs-status-message span[tooltip]:hover:before {
  display: block;
}
.vs-status-latency {
  border-left: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
  width: 1%;
  white-space: nowrap;
}
</style>
