<template>
  <div>{{ value }}</div>
</template>
<script>
  export default {
    props: [ 'data', 'options' ],
    computed: {
      value: function() {
        if (this.options.select) return this.options.select[this.data.value];
        return this.data.value;
      }
    }
  }
</script>
