<template>
  <div v-show="visible" class="vs-indicator">
    <svg :style="{ top: indicator_top, left: indicator_left }" width="75px" height="75px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="19.9128" fill="none" stroke="#1d3f72" stroke-width="4">
        <animate attributeName="r" calcMode="spline" values="0;40" keyTimes="0;1" dur="1" keySplines="0 0.2 0.8 1" begin="-0.5s" repeatCount="indefinite" />
        <animate attributeName="opacity" calcMode="spline" values="1;0" keyTimes="0;1" dur="1" keySplines="0.2 0 0.8 1" begin="-0.5s" repeatCount="indefinite" />
      </circle>
      <circle cx="50" cy="50" r="37.405" fill="none" stroke="#5699d2" stroke-width="4">
        <animate attributeName="r" calcMode="spline" values="0;40" keyTimes="0;1" dur="1" keySplines="0 0.2 0.8 1" begin="0s" repeatCount="indefinite" />
        <animate attributeName="opacity" calcMode="spline" values="1;0" keyTimes="0;1" dur="1" keySplines="0.2 0 0.8 1" begin="0s" repeatCount="indefinite" />
      </circle>
    </svg>
  </div>
</template>
<script>
  export default {
    created: function() {
      this.$http.interceptors.request.use((config) => {
        this.indicator = this.$http.indicator;
        if (this.indicator != false) this.visible = true;
        return config;
      }, (error) => {
        return Promise.reject(error);
      });
      this.$http.interceptors.response.use((response) => {
        this.visible = false;
        return response;
      }, (error) => {
        this.visible = false;
        return Promise.reject(error);
      });
    },
    data: function () {
      return {
        indicator: null,
        visible: false
      }
    },
    computed: {
      indicator_left: function() {
        if (!this.indicator) return;
        if (this.indicator.clientX) return this.indicator.clientX + 'px';
        else if(this.indicator.target) return this.$util.get_position(this.indicator.target).x + 'px';
      },
      indicator_top: function() {
        if (!this.indicator) return;
        if (this.indicator.clientY) return this.indicator.clientY + 'px';
        else if(this.indicator.target) return this.$util.get_position(this.indicator.target).y + 'px';
      }
    },
  }
</script>
<style>
.vs-indicator {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: progress;
  z-index: 999;
}
.vs-indicator > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
