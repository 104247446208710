<template>
  <div class="vs-duration buttons has-addons">
    <input maxlength="3" class="button" type="text" :value="hour" @input="filter('hour', $event)" @paste.prevent.stop="">
    <div class="vs-duration-spinner button is-static">
      <button @click="spin_up('hour', $event)" class="fas fa-caret-up"></button>
      <button @click="spin_down('hour', $event)" class="fas fa-caret-down"></button>
    </div>
    <input maxlength="2" class="button" type="text" :value="minute" @input="filter('minute', $event)">
    <div class="vs-duration-spinner button is-static">
      <button @click="spin_up('minute', $event)" class="fas fa-caret-up"></button>
      <button @click="spin_down('minute', $event)" class="fas fa-caret-down"></button>
    </div>
    <input maxlength="2" class="button" type="text" :value="second" @input="filter('second', $event)">
    <div class="vs-duration-spinner button is-static">
      <button @click="spin_up('second', $event)" class="fas fa-caret-up"></button>
      <button @click="spin_down('second', $event)" class="fas fa-caret-down"></button>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['disabled', 'options', 'value'],
    created: function() {
      // translate value into hour, minute, second
      if (this.value) {
        let parts = this.value.toString().split(':');
        if (parts.length == 3) {
          this.hour = Number.parseInt(parts[0]);
          if (isNaN(this.hour)) this.hour = 0;
          this.minute = Number.parseInt(parts[1]);
          if (isNaN(this.minute)) this.minute = 0;
          this.second = Number.parseInt(parts[2]);
          if (isNaN(this.second)) this.second = 0;
        }
      }
    },
    data: function () {
      return {
        hour: 0,
        minute: 0,
        second: 0,
      }
    },
    computed: {
    },
    methods: {
      emit_value: function() {
        this.$emit('input', this.hour + ':' + this.minute + ':' + this.second);
      },
      filter: function(input, e) {
        let max = (input == 'hour' ? 999 : 59);
        if (e.data == null || /\d/.test(e.data)) {
          this[input] = Number.parseInt(e.target.value);
          if (isNaN(this[input])) this[input] = 0;
          else if (this[input] > max) this[input] = max;
        }
        else e.target.value = this[input];
        this.emit_value();
      },
      spin_up: function(input, e) {
        let max = (input == 'hour' ? 999 : 59);
        this[input]++;
        if (this[input] > max) this[input] = max;
        this.emit_value();
        e.target.blur();
      },
      spin_down: function(input, e) {
        let max = (input == 'hour' ? 999 : 59);
        this[input]--;
        if (this[input] < 0) this[input] = 0;
        else if (this[input] > max) this[input] = max;
        this.emit_value();
        e.target.blur();
      }
    }
  }
</script>
<style>
.vs-duration {
  display: flex;
  flex-wrap: nowrap !important;
}
.vs-duration > button {
  padding: 0;
  width: 2rem;
  text-align: center;
}
.vs-duration > input {
  flex: 1 1 auto;
  padding: 0;
  width: 1px;
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}
.vs-duration > input::-webkit-inner-spin-button,
.vs-duration > input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.vs-duration-spinner {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.vs-duration-spinner > button {
  border: none;
  height: 50%;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  cursor: pointer;
}
.vs-duration-spinner > button:focus {
  outline: 1px solid #3273dc;
  z-index: 10;
}

</style>
