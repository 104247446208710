<template>
  <div ref="tooltip" :style="{ top: data.y + 'px', left: data.x + 'px'}" class="vs-tooltip">
    <span v-if="data.text">{{ data.text }}</span>
    <table v-else-if="data.components">
      <tr v-for="comp in data.components" :key="comp.column">
        <td style="white-space:nowrap" v-html="comp.label"></td>
        <td style="word-break:break-word">
          <vs-output :data="comp" :options="comp.options" :ui="comp.ui"></vs-output>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
  import VsOutput from './VsOutput.vue'
  export default {
    components: {
      'vs-output': VsOutput
    },
    props: [ 'data' ],
    mounted: function() {
      this.$nextTick(() => {
        let top = 0;
        let left = 0;
        if (this.data.y + this.$refs.tooltip.offsetHeight > document.documentElement.clientHeight) top = this.data.y - this.$refs.tooltip.offsetHeight - 10;
        else top = this.data.y + 10;
        if (this.data.x + this.$refs.tooltip.offsetWidth > document.documentElement.clientWidth) left = this.data.x - this.$refs.tooltip.offsetWidth;
        else left = this.data.x;
        if (this.$refs.tooltip.offsetWidth / document.documentElement.clientWidth >= 0.75 || left < 0) left = (document.documentElement.clientWidth / 2) - (this.$refs.tooltip.offsetWidth / 2);
        if (top < 0) top = 10;
        this.data.x = left;
        this.data.y = top;
      });
    }
  }
</script>
<style>
.vs-tooltip {
  background-color: whitesmoke;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 3px -2px #888;
  min-width: 10em;
  max-width: 25em;
  padding: 0.5em;
  position: fixed;
  pointer-events: none;
  z-index: 20;
}
.vs-tooltip td {
  border: 1px solid #ddd;
  padding: 0 0.5em;
}
</style>
