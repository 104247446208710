<template>
  <nav v-if="data.visible" @blur="$emit('close')" ref="contextmenu" :style="{ top: data.y + 'px', left: data.x + 'px'}" class="vs-contextmenu dropdown-content noselection" tabindex="-1">
    <template v-for="(item,index) in data.items">
      <div v-if="item.header" :style="{ backgroundColor: item.background, color: item.color }" :key="'item_' + index" class="vs-contextmenu-header">{{ item.header }}</div>
      <a v-else-if="item.label" :disabled="item.disabled" @contextmenu.prevent.stop="" @click="click(item, $event)" :key="'item_' + index" class="dropdown-item">
        <span v-if="item.icon" class="icon is-small">
          <i :class="item.icon" class="fas"></i>
        </span>
        <span>{{ item.label }}</span>
        <input v-if="item.upload" type="file" @change="fileupload(item, $event)" :ref="item.name + '_file'">
        <input v-else-if="item.upload_multiple" multiple type="file" @change="fileupload(item, $event)" :ref="item.name + '_file'">
      </a>
      <hr v-else-if="item.divider" class="vs-contextmenu-hr" :key="'item_' + index" />
    </template>
  </nav>
</template>
<script>
  export default {
    props: [ 'data', 'vsid' ],
    mounted: function() {
      this.$nextTick(() => {
        let top = 0;
        let left = 0;
        if (this.data.y + this.$refs.contextmenu.offsetHeight > document.documentElement.clientHeight) top = this.data.y - this.$refs.contextmenu.offsetHeight;
        else top = this.data.y;
        if (this.data.x + this.$refs.contextmenu.offsetWidth > document.documentElement.clientWidth) left = this.data.x - this.$refs.contextmenu.offsetWidth;
        else left = this.data.x;
        if (this.$refs.contextmenu.offsetWidth / document.documentElement.clientWidth >= 0.75 || left < 0) left = (document.documentElement.clientWidth / 2) - (this.$refs.contextmenu.offsetWidth / 2);
        this.data.x = left;
        this.data.y = top;
        this.$refs.contextmenu.focus();
      });
    },
    methods: {
      fileupload: function(item, e) {
        let form_data = new FormData();
        if (item.upload_multiple) {
          for (var i = 0; i < e.target.files.length; i++) form_data.append('files[]', e.target.files[i]);
        } else form_data.append('files[]', e.target.files[0]);
        this.$http({
          method: item.action.method || 'POST',
          url: item.action.url.replace(':id', item.action.id || 0),
          headers: { 'Content-Type': 'multipart/form-data', 'vs-id': this.vsid },
          data: form_data
        }).then((result) => {
          this.$emit('close');
        }).catch((error) => {});
      },
      click: function(item, e) {
        if (item.upload || item.upload_multiple) {
          this.$http.indicator = e;
          this.$refs[item.name + '_file'][0].click();
        } else if (item.perform) {
          item.perform();
          this.$emit('close');
        } else if (item.action) {
          let options = { method: item.action.method, url: item.action.url.replace(':id', item.action.id || 0), headers: { 'vs-id': this.vsid } };
          if (item.action.params) options[item.action.method.toLowerCase() == 'get' ? 'params' : 'data'] = item.action.params;
          if (item.action.confirm) {
            var header = this.$i18n.translate('scaffold', 'texts.confirmation', 'Confirmation') + ': ' + item.label;
            var content = item.action.confirm_text || item.action.label;
            if (item.action.type == 'batch') {
              var labels = [];
              for (var i=0; i < item.action.params['_batch_'].length; i++) labels.push(item.action.params['_batch_'][i].label);
              content = '<ol><li>' + labels.join('</li><li>') + '</li></ol>';
              header += ' (' + this.$i18n.translate('scaffold', 'batch.label', 'Batch') + ')';
            }
            this.$dialog.confirm(header, content, item.label, this.$i18n.translate('scaffold', 'buttons.cancel', 'Cancel'), 'is-danger').then(([ee, f]) => {
              this.$http.indicator = ee;
              this.$http(options).then((result) => { f(result) }).catch((error) => {});
            }).catch(([ee,f]) => { f() });
            this.$emit('close');
          } else {
            this.$http.indicator = e;
            this.$http(options).then((result) => {
              this.$emit('close');
            }).catch((error) => {});
          }
        } else {
          this.$emit('close');
        }
      }
    },
  }
</script>
<style>
.vs-contextmenu input[type=file] {
  display: none;
}
.vs-contextmenu {
  box-shadow: 5px 5px 3px -2px #888 !important;
  cursor: default;
  min-width: 10em;
  padding: 0 !important;
  position: fixed;
  z-index: 20;
}
.vs-contextmenu:focus {
  outline: none;
}
.vs-contextmenu-header {
  background-color: #5c7088;
  color: #fff;
  padding: 0 1em;
  text-align: center;
}
.vs-contextmenu-hr {
  border-left: 1px solid #888;
	display: flex;
	flex-basis: 100%;
	align-items: center;
	color: rgba(0, 0, 0, 0.35);
	margin: 0;
}
.vs-contextmenu-hr::before,
.vs-contextmenu-hr::after {
	content: "";
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.15);
	height: 1px;
	font-size: 0;
	line-height: 0;
}
.vs-contextmenu a {
  border-left: 1px solid #888;
}
.vs-contextmenu span + span { margin-left: 0.15rem }
.vs-contextmenu a.dropdown-item { padding-right: 1rem }
.vs-contextmenu a:last-child {
  border-bottom: 1px solid #888;
  border-radius: 0 0 4px 4px;
}
</style>
