<template>
  <component :is="get_ui" :data="data" :options="options"></component>
</template>

<script>
  import VsOutputCheckbox from './outputs/VsOutputCheckbox.vue'
  import VsOutputCheckboxMultiple from './outputs/VsOutputCheckboxMultiple.vue'
  import VsOutputDatetime from './outputs/VsOutputDatetime.vue'
  import VsOutputDuration from './outputs/VsOutputDuration.vue'
  import VsOutputDecimal from './outputs/VsOutputDecimal.vue'
  import VsOutputSelect from './outputs/VsOutputSelect.vue'
  import VsOutputString from './outputs/VsOutputString.vue'
  export default {
    components: {
      'vs-output-checkbox': VsOutputCheckbox,
      'vs-output-checkbox-multiple': VsOutputCheckboxMultiple,
      'vs-output-datetime': VsOutputDatetime,
      'vs-output-decimal': VsOutputDecimal,
      'vs-output-duration': VsOutputDuration,
      'vs-output-select': VsOutputSelect,
      'vs-output-string': VsOutputString
    },
    props: [ 'ui', 'options', 'data' ],
    computed: {
      get_ui: function() {
        if (('vs-output-' + this.ui) in this.$options.components) return ('vs-output-' + this.ui);
        return 'vs-output-string';
      }
    },
    data: function () {
      return {
      }
    },
  }
</script>
