<template>
  <component :is="get_ui" @blur="blur" @input="input" @enter="enter" @batching="$emit('batching', $event)" @validation="validate($event)" :options="options" :container="container" :column="column" :label="label" :value="value" :key="column" ref="component"></component>
</template>

<script>
  import VsInputCheckbox from './inputs/VsInputCheckbox.vue'
  import VsInputDatetime from './inputs/VsInputDatetime.vue'
  import VsInputDecimal from './inputs/VsInputDecimal.vue'
  import VsInputInteger from './inputs/VsInputInteger.vue'
  import VsInputOTP from './inputs/VsInputOTP.vue'
  import VsInputPassword from './inputs/VsInputPassword.vue'
  import VsInputSelect from './inputs/VsInputSelect.vue'
  import VsInputString from './inputs/VsInputString.vue'
  import VsInputText from './inputs/VsInputText.vue'
  // import VsInputCKEditor from './inputs/VsInputCKEditor.vue'
  import VsInputTinyMCE from './inputs/VsInputTinyMCE.vue'
  export default {
    components: {
      'vs-input-boolean': VsInputCheckbox,
      'vs-input-checkbox': VsInputCheckbox,
      'vs-input-datetime': VsInputDatetime,
      'vs-input-decimal': VsInputDecimal,
      'vs-input-integer': VsInputInteger,
      'vs-input-otp': VsInputOTP,
      'vs-input-password': VsInputPassword,
      'vs-input-select': VsInputSelect,
      'vs-input-string': VsInputString,
      'vs-input-text': VsInputText,
      // 'vs-input-editor': VsInputCKEditor
      'vs-input-editor': VsInputTinyMCE
    },
    props: [ 'container', 'label', 'column', 'options', 'value', 'ui' ],
    computed: {
      get_ui: function() {
        if (('vs-input-' + this.ui) in this.$options.components) return ('vs-input-' + this.ui);
        return 'vs-input-string';
      }
    },
    data: function () {
      return {
        last_value: null
      }
    },
    methods: {
      blur: function(e) {
        this.$emit('blur', e);
      },
      enter: function(e) {
        this.$emit('enter', e)
      },
      focus: function() {
        this.$refs.component.focus();
      },
      input: function(v) {
        var changed = (v != this.value);
        this.$emit('input', v);
        if (!changed) return;
        // this.options.error = null;
        if (Array.isArray(this.options.dependencies)) {
          var data = {};
          // data[this.options.column || this.column] = { value: v };
          var components = {};
          var dependencies = this.options.dependencies;
          for (let comp of this.container.components) {
            data[comp.options.column || comp.column] = { value: comp.value };
            if (dependencies.indexOf(comp.column) >= 0) {
              components[comp.column] = comp;
              comp.options['is_loading'] = true;
            }
          }
          let params = { '_refresh_': data };
          if (this.container.batch) params['_batch_'] = true;
          this.$axios({
            url: this.container.path,
            params: params
          }).then((result) => {
            for (let comp of result.data['_panel_'].components) {
              if (dependencies.indexOf(comp.column) >= 0) Object.assign(components[comp.column], comp);
            }
          }).catch((error) => {});
        }
      },
      validate: function(result) {
        if (this.options.disabled) result = true;
        this.$emit('validation', result);
      }
    }
  }
</script>
