<template>
  <div :class="{ 'is-active': dropdown }" :style="{ width: options.width || '100%' }" class="vs-select dropdown">
    <div :style="{ width: options.width || '100%' }" class="dropdown-trigger">
      <div class="field has-addons">
        <div :class="{ 'has-icons-left': options.icon, 'is-loading': is_loading }" class="control has-icons-right is-expanded">
          <span v-if="options.icon" class="icon is-small is-left">
            <i :class="options.icon" class="fas"></i>
          </span>
          <input @blur="blur('select', $event)" @focus="focus('select', $event)" @click="toggle" :value="selection_label" :disabled="ajax || disabled || options.readonly || options.is_loading" :class="css_class" class="input" type="text" ref="select" readonly>
          <span class="icon is-small is-right">
            <i @click="select(null, null)" :class="[ icon, show_clear && value ? 'vs-icon-event' : ''] " class="fas"></i>
          </span>
        </div>
      </div>
    </div>
    <div :style="{ width: options.width || '100%' }" class="dropdown-menu">
      <div class="dropdown-content" ref="dropdown">
        <div v-if="show_search" class="vs-select-searchbox">
          <input v-model="search" @blur="blur('search', $event)" @focus="focus('search', $event)" @keyup="do_search" :placeholder="$i18n.translate('scaffold', 'select.search', 'Search')" class="input" ref="search">
        </div>
        <div class="vs-select-items">
          <a :class="{ 'vs-select-selected': (item.value == value) }" v-for="(item,index) in sorted_items" @mousedown="select(item.value, index)" class="dropdown-item" :key="'item_' + index" :ref="'item_' + index">{{ item.label }}</a>
          <span v-if="items.length == 0" class="dropdown-item">{{ $i18n.translate('scaffold', 'select.empty', 'No items found') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['disabled', 'options', 'value'],
    created: function() {
      this.do_search = this.$util.debounce(() => {
        if (this.options.ajax) this.ajax_load();
        else this.filter_options();
      }, 500);
      if (this.options.ajax) this.ajax_load();
      else this.filter_options();
    },
    data: function () {
      return {
        ajax: false,
        focuses: {
          select: false,
          search: false
        },
        dropdown: false,
        pagination: {
          page: 1,
          size: 100
        },
        search: '',
        selection: {},
        selection_index: null,
        items: []
      }
    },
    watch: {
      options: function (value, newvalue) {
        if (this.options.ajax) this.ajax_load();
        else this.filter_options();
      }
    },
    computed: {
      is_loading: function() {
        return (this.ajax || this.options.is_loading ? true : false);
      },
      selection_label: function() {
        if (this.ajax) return this.$i18n.translate('scaffold', 'texts.loading', 'loading...');
        var item = this.items.find((item) => { return item.value == this.value });
        if (item) return item.label;
        else this.$emit('input', null);
        return (this.options.placeholder || this.$i18n.translate('scaffold', 'select.placeholder', '- select -'))
      },
      show_clear: function() {
        if (this.options.allow_clear) return this.options.allow_clear;
        return this.options.required != true;
      },
      show_search: function() {
        if (this.options.ajax) return true;
        if (!this.options.select) return false;
        if (Object.keys(this.options.select).length < 10) return false;
        return (this.options.allow_search != false);
      },
      css_class: function() {
        if (this.disabled || this.options.readonly) return;
        return 'input ' + (this.validated ? 'is-success' : 'is-danger');
      },
      icon: function() {
        if (this.is_loading) return '';
        if (this.value && this.show_clear) return 'fa-times';
        return (this.dropdown ? 'fa-angle-up' : 'fa-angle-down');
      },
      validated: function() {
        var validating = true;
        if ((this.options.allow_blank == false || this.options.required == true) && this.value == null) validating = false;
        this.$emit('validation', validating);
        return validating;
      },
      sorted_items: function() {
        return this.items;
        // let sort server side
        if (this.options.sorted) return this.items;
        return this.items.sort((a, b) => {
          if (a.label > b.label) return 1;
          else if (a.label < b.label) return -1;
          return 0;
        });
      }
    },
    methods: {
      ajax_load: function() {
        if (this.options.ajax) {
          this.selection_index = null;
          this.ajax = true;
          this.$axios({
            url: this.options.ajax.url,
            params: {
              '_select_': true,
              '_pagination_': { page: this.pagination.page, size: this.pagination.size },
              '_searching_': { text: this.search },
              '_sorting_': { }
            }
          }).then((response) => {
            this.ajax = false;
            if (response.data.items) {
              this.items = [];
              for (let item of response.data.items) this.items.push({ value: item.id, label: item._label_ });
            }
          }).catch((error) => {
            this.ajax = false;
          });
        }
      },
      filter_options: function() {
        this.selection_index = null;
        if (this.options.select) {
          let items = Array.isArray(this.options.select) ? this.options.select.map(opt => { return { value: opt[0], label: opt[1] } }) : Object.keys(this.options.select).map(k => { return { value: k, label: this.options.select[k] } });
          this.items = items.filter(item => item.label.toLowerCase().indexOf(this.search.toLowerCase()) >= 0);
        } else this.items = [];
      },
      blur: function(target, e) {
        this.focuses[target] = false;
        this.$emit('blur', e);
        setTimeout(() => {
          if (!this.focuses.select && !this.focuses.search) this.dropdown = false;
        }, 100);
      },
      focus: function(target, e) {
        this.focuses[target] = true;
        this.$refs[target].focus();
      },
      select: function(value, index) {
        this.selection_index = index;
        this.$emit('input', value);
        this.dropdown = false;
      },
      toggle: function() {
        if (this.disabled) return;
        this.dropdown = !this.dropdown;
        if (!this.dropdown) return;
        this.$nextTick(() => {
          this.$refs.dropdown.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
          if (this.show_search) this.$refs.search.focus();
          if (this.selection_index == null) {
            for (let i=0; i < this.sorted_items.length; i++) {
              if (this.sorted_items[i].value == this.selection.value) this.selection_index = i;
            }
          }
          if (this.selection_index) this.$refs['item_' + this.selection_index][0].scrollIntoView({ block: 'nearest', inline: 'start' });
        });
      }
    }
  }
</script>
<style>
.vs-select .dropdown-content {
  border: 1px solid #bbb;
  overflow: hidden;
  padding: 0;
}
.vs-select-searchbox {
  border-bottom: 1px solid #bbb;
  padding: 0.5rem;
}
.vs-select-selected {
  font-weight: bold;
}
.vs-select-items {
  max-height: 15rem;
  overflow: auto;
}
.vs-select-items > a {
  padding: 0.2rem 0.5rem;
}
.vs-select-items > a:hover {
  background-color: #ccc;
}
</style>
