<template>
  <div class="vs-number">
    <input class="button" type="text" :maxlength="maxlength" :value="value" @input="filter" @paste.prevent.stop="">
    <div v-if="spinner" class="vs-number-spinner">
      <button @click="spin_up('hour', $event)" class="fas fa-caret-up"></button>
      <button @click="spin_down('hour', $event)" class="fas fa-caret-down"></button>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['disabled', 'integer', 'maxlength', 'max', 'min', 'negative', 'spinner', 'value'],
    props: {
      disabled: {
        type: Boolean,
        default: function() { return false }
      },
      integer: {
        type: Boolean,
        default: function() { return false }
      },
      maxlength: {
        type: Number,
      },
      max: {
        type: Number,
      },
      min: {
        type: Number,
      },
      negative: {
        type: Boolean,
        default: function() { return true }
      },
      spinner: {
        type: Boolean,
        default: function() { return true }
      },
      value: {
        type: Number
      }
    },
    methods: {
      emit_value: function() {
        this.$emit('input', this.hour + ':' + this.minute + ':' + this.second);
      },
      filter: function(e) {
        let v = this.integer ? Number.parseInt(e.target.value) : Number.parseFloat(e.target.value);
        let invalid = isNaN(v);
        if (!invalid) {
        }

        if (invalid) e.target.value = this.value;
        else this.$emit('input', v);

        if (e.data == null || /\d/.test(e.data)) {

          let v = Number.parseInt(e.target.value);
          if (isNaN(this[input])) this[input] = 0;
          else if (this[input] > max) this[input] = max;
          this.$emit('input', this.hour + ':' + this.minute + ':' + this.second);
        }
        else e.target.value = this[input];
        this.emit_value();
      },
      spin_up: function(input, e) {
        let max = (input == 'hour' ? 999 : 59);
        this[input]++;
        if (this[input] > max) this[input] = max;
        this.emit_value();
        e.target.blur();
      },
      spin_down: function(input, e) {
        let max = (input == 'hour' ? 999 : 59);
        this[input]--;
        if (this[input] < 0) this[input] = 0;
        else if (this[input] > max) this[input] = max;
        this.emit_value();
        e.target.blur();
      }
    }
  }
</script>
<style>
.vs-number {
  display: flex;
}
.vs-number > input {
  flex: 1 1 auto;
}
.vs-number-spinner {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  flex-direction: column;
  z-index: 5;
}
.vs-number-spinner > button {
  border: none;
  height: 50%;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  cursor: pointer;
}
.vs-number-spinner > button:focus {
  outline: 1px solid #3273dc;
  z-index: 10;
}

</style>
