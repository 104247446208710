<template>
  <div v-if="options.readonly"></div>
  <div v-else-if="options.vertical" class="field vs-input-password-vertical">
    <label class="label">{{ label }}</label>
    <div :class="options.icon ? 'has-icons-left' : ''" class="control">
      <input @blur="blur" @keyup.enter="$emit('enter', $event)" @input="input" :disabled="options.disabled" :value="hidden" :placeholder="options.placeholder" :class="validated ? 'is-success' : 'is-danger'" class="input" ref="input" type="url" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false">
      <span v-if="options.icon" class="icon is-small is-left">
        <i :class="options.icon" class="fas"></i>
      </span>
    </div>
  </div>
  <div v-else class="field is-horizontal vs-input-password">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <div :class="options.icon ? 'has-icons-left' : ''" class="control">
            <input @blur="blur" @keyup.enter="$emit('enter', $event)" @input="input" :disabled="options.disabled" :value="hidden" :placeholder="options.placeholder" :class="validated ? 'is-success' : 'is-danger'" class="input" ref="input" type="url" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false">
            <span v-if="options.icon" class="icon is-small is-left">
              <i :class="options.icon" class="fas"></i>
            </span>
          </div>
        </div>
        <div :class="{'has-text-danger': options.error}" class="help vs-description">{{ options.error || options.description }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: [ 'container', 'column', 'label', 'options', 'value' ],
    data: function () {
      return {
        hidden: '',
        password: '',
        timer: null
      }
    },
    mounted: function() {
      if (this.options.autofocus) this.$refs.input.focus();
    },
    computed: {
      validated: function() {
        var validations = [];
        if (this.options.input) validations.push(false);
        if (this.options.equal) validations.push(this.value == this.options.equal);
        if (this.options.required) validations.push(this.value && this.value.length > 0);
        if (this.options.minlength) validations.push(this.value && this.value.length >= this.options.minlength);
        if (this.options.match && this.container) {
          for (var i=0; i < this.container.components.length; i++) {
            if (this.container.components[i].column == this.options.match) validations.push(this.container.components[i].value == this.value);
          }
        }
        var validated = true;
        for (var i=0; i < validations.length; i++) if (!validations[i]) validated = false;
        if (this.options.batch && this.batched != 'update') validated = true;
        this.$emit('validation', validated);
        return validated;
      }
    },
    methods: {
      blur: function(e) {
        this.$emit('blur', e);
      },
      focus: function() {
        this.$refs.input.focus();
      },
      input: function(e) {
        var deleting = false;
        var pos = e.target.selectionStart;
        var chars = e.target.value.split('');
        var pw = this.password.split('');
        if (chars.length < pw.length) {
          deleting = true;
          pw.splice(pos, pw.length - chars.length);
        }
        var k = 0;
        for (var i = 0; i < chars.length; i++) {
          if (k >= pw.length) break;
          if (chars[i] == '\u2022') {
            chars[i] = pw[k];
            k++;
          }
        }
        var tmp = '\u2022'.repeat(chars.length).split('');
        if (!deleting) tmp[pos-1] = chars[pos-1];
        this.password = chars.join('');
        this.hidden = tmp.join('');
        this.$nextTick(() => { e.target.selectionStart = e.target.selectionEnd = pos });
        this.$emit('input', this.password);
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.hidden = '\u2022'.repeat(this.password.length);
          this.$nextTick(() => { e.target.selectionStart = e.target.selectionEnd = pos });
        }, 500);
      },
    }
  }
</script>
