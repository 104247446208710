<template>
  <div v-if="data.html_safe" v-html="value"></div>
  <div v-else>{{ value }}</div>
</template>
<script>
  export default {
    props: [ 'data', 'options' ],
    computed: {
      value: function() {
        if (this.data.value == null || this.data.value == undefined) return '';
        return this.$util.truncate(this.data.value.toString(), 200);
      }
    }
  }
</script>
