<template>
  <div v-if="options.vertical" class="field vs-input-integer-vertical">
    <label class="label">{{ label }}</label>
    <span v-if="options.readonly">{{ value }}</span>
    <div v-else :class="{ 'is-grouped': options.batch }" class="field">
      <div v-if="options.batch" class="control">
        <div class="select">
          <select v-model="batched" @change="$emit('batching', batched)">
            <option value="">{{ $i18n.translate('scaffold', 'batch.ignore', 'Ignore') }}</option>
            <option value="update">{{ $i18n.translate('scaffold', 'batch.update', 'Update') }}</option>
            <option v-if="!options.required" value="nullify">{{ $i18n.translate('scaffold', 'batch.nullify', 'Nullify') }}</option>
          </select>
        </div>
      </div>
      <div :class="options.icon ? 'has-icons-left' : ''" class="control is-expanded">
        <label v-if="options.readonly">{{ value }}</label>
        <input v-else @blur="blur" @input="input" @keyup.enter="enter" type="number" :class="css_class" :placeholder="options.placeholder" :value="value" ref="input">
        <span v-if="options.icon" class="icon is-small is-left">
          <i :class="options.icon" class="fas"></i>
        </span>
      </div>
    </div>
  </div>
  <div v-else class="vs-input-integer field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <span v-if="options.readonly">{{ value }}</span>
      <div v-else :class="{ 'is-grouped': options.batch }" class="field">
        <div v-if="options.batch" class="control">
          <div class="select">
            <select v-model="batched" @change="$emit('batching', batched)">
              <option value="">{{ $i18n.translate('scaffold', 'batch.ignore', 'Ignore') }}</option>
              <option value="update">{{ $i18n.translate('scaffold', 'batch.update', 'Update') }}</option>
              <option v-if="!options.required" value="nullify">{{ $i18n.translate('scaffold', 'batch.nullify', 'Nullify') }}</option>
            </select>
          </div>
        </div>
        <div class="control is-expanded">
          <div :class="options.icon ? 'has-icons-left' : ''" class="control">
            <input @blur="blur" @input="input" @keyup.enter="enter" type="number" :class="css_class" :placeholder="options.placeholder" :disabled="options.disabled || (options.batch && batched != 'update')" :value="value" ref="input">
            <span v-if="options.icon" class="icon is-small is-left">
              <i :class="options.icon" class="fas"></i>
            </span>
          </div>
        </div>
        <div :class="{'has-text-danger': options.error}" class="help vs-description">{{ options.error || options.description }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: [ 'container', 'column', 'label', 'options', 'value' ],
    data: function () {
      return {
        batched: ''
      }
    },
    mounted: function() {
      if (this.options.autofocus) this.$refs.input.focus();
    },
    computed: {
      css_class: function() {
        return 'input ' + (this.validated || this.options.disabled ? 'is-success' : 'is-danger');
      },
      validated: function() {
        var validating = true;
        if (this.options.validation) validating = this.options.validation(this.value);
        else {
          var value = this.value;
          if (typeof value != 'number') value = parseFloat(this.value);
          if (this.options.minimum && value < this.options.minimum) validating = false;
          if (this.options.maximum && value > this.options.maximum) validating = false;
        }
        this.$emit('validation', validating);
        return validating;
      }
    },
    methods: {
      blur: function(e) {
        this.$emit('blur', e);
      },
      enter: function(e) {
        this.$emit('enter', e)
      },
      focus: function() {
        this.$refs.input.focus();
      },
      input: function(e) {
        this.$emit('input', e.target.value);
      }
    }
  }
</script>
