<template>
  <nav v-if="data" class="vs-navbar">
    <div class="vs-navbar-menu" :class="{ 'is-active': burger_active }">
      <div class="vs-navbar-start">
        <vs-navbar-item @navigate="navigate" v-for="(item,index) in data.start" :data="item" :key="'item_start_' + index"></vs-navbar-item>
      </div>
      <div class="vs-navbar-end">
        <vs-navbar-item @navigate="navigate" v-for="(item,index) in data.end" :data="item" :key="'item_end_' + index"></vs-navbar-item>
      </div>
    </div>
    <div class="vs-navbar-controls">
      <a class="vs-navbar-control vs-navbar-burger" :class="{ 'is-active': burger_active }" @click="burger_active = !burger_active">
        <span class="icon"><i class="fas" :class="burger_active ? 'fa-times' : 'fa-bars'"></i></span>
      </a>
      <a class="vs-navbar-control" @click.prevent.stop="$emit('help')">
        <span class="icon"><i class="fas fa-question"></i></span>
        <span class="vs-navbar-text">{{ $i18n.translate('scaffold', 'texts.help', 'Help') }}</span>
      </a>
    </div>
  </nav>
</template>

<script>
  import security_icon from '../../images/security.png'
  import VsNavbarItem from './navbar/VsNavbarItem.vue'
  export default {
    components: {
      'vs-navbar-item': VsNavbarItem
    },
    data: function () {
      return {
        burger_active: false,
        data: null,
        fontsize: 100,
        fullscreen: false
      }
    },
    created: function() {
      document.addEventListener('fullscreenchange', (event) => {
        this.fullscreen = !!document.fullscreenElement;
      });
      this.$http.interceptors.request.use((config) => {
        if (this.data) config.headers['vs-navbar-cache-key'] = this.data.cache_key;
        return config;
      }, (error) => {
        return Promise.reject(error);
      });
      this.$http.interceptors.response.use((response) => {
        if (response.data['_navbar_']) this.data = response.data['_navbar_'];
        return response;
      }, (error) => {
        return Promise.reject(error);
      });
    },
    methods: {
      navigate: function(data, e) {
        this.burger_active = false;
        this.$emit('navigate', data, e);
      },
      setFontsize: function(increase) {
        if (increase) this.fontsize += 5;
        else this.fontsize -= 5;
        if (this.fontsize > 140) this.fontsize = 140;
        else if (this.fontsize < 70) this.fontsize = 70;
        document.body.style.fontSize = this.fontsize + '%';
        document.documentElement.style.fontSize = this.fontsize + '%';
      },
      toggleFullscreen: function() {
        if (this.fullscreen) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
          }
        } else {
          var elem = document.documentElement;
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
          }
        }
        this.fullscreen = !this.fullscreen;
      }
    }
  }
</script>
<style>
.vs-navbar {
  align-items: center;
  background: linear-gradient(to bottom, #1B6C97, #2183B8);
  box-shadow: 0px 2px 4px #666666;
  display: flex;
  min-height: 2rem;
  position: relative;
  z-index: 10;
}
.vs-navbar-controls {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
}
.vs-navbar-control,
.vs-navbar-control:hover,
.vs-navbar-control:focus {
  padding: 0.5rem 2px;
  color:#fefefe;
  text-shadow: 1px 1px 3px #000;
}
.vs-navbar-burger {
  padding: 0.5rem !important;
  cursor: pointer;
  display: none;
}
.vs-navbar-burger:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.vs-navbar-menu {
  display: flex;
  flex: 1 1;
}
.vs-navbar-start {
  display: flex;
  flex: 1 1;
  position: relative;
}
.vs-navbar-end {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
@media screen and (max-width: 768px) {
  .vs-navbar-controls {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
  }
  .vs-navbar-text {
    display: none;
  }
  .vs-navbar-burger {
    display: block;
    position: absolute;
    left: 0;
  }
  .vs-navbar-menu {
    display: none;
    flex-direction: column;
    top: 100%;
    width: 100%;
    position: absolute;
    max-height: 75vh;
    overflow-y: auto;
    padding: 0.5em;
    background-color: aliceblue;
    border: 1px solid #ccc;
    box-shadow: 0 5px 3px -2px #888
  }
  .vs-navbar-menu.is-active {
    display: flex;
  }
  .vs-navbar-start,
  .vs-navbar-end {
    font-size: 1.1rem;
    flex-direction: column;
  }
  .vs-navbar-item {
    padding: 0.25rem;
  }
  .vs-navbar-group {
    padding-left: 1.5em;
  }
}
</style>
