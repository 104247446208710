<template>
  <div>
    <div v-for="item in data.value">
      <input disabled type="checkbox" :indeterminate.prop="item.indeterminate" :checked="item.value">
      <label :title="item.tooltip">{{ item.label }}</label>
    </div>
  </div>
</template>
<script>
  export default {
    props: [ 'data', 'options' ],
  }
</script>
