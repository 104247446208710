<template>
  <div class="vs-dialog modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card" :style="{ width: data.width }">
      <template v-if="data.components">
        <header class="modal-card-head">
          <p class="modal-card-title noselection" v-html="data.label"></p>
          <button @click="$emit('close')" class="delete"></button>
        </header>
        <div class="modal-card-body">
          <template v-for="(component,index) in data.components">
            <vs-input v-if="component.ui" v-model="component.value" @batching="component.batched = $event" @validation="component.validated = $event" :ui="component.ui" :column="component.column" :options="component.options" :label="component.label" :key="'component_' + index" :container="data" :ref="component.column"></vs-input>
          </template>
        </div>
        <footer class="modal-card-foot">
          <button v-for="(button,index) in buttons" :key="'button_' + index" :class="css_class(button)" class="button" @click="click(button, $event)" :disabled="disabled(button)" v-html="button.label"></button>
        </footer>
      </template>
      <template v-else-if="data.promise">
        <header class="modal-card-head">
          <p class="modal-card-title noselection" v-html="data.label"></p>
          <button @click="close" class="delete"></button>
        </header>
        <div class="modal-card-body">
          <slot>
            <div v-html="data.text"></div>
          </slot>
        </div>
        <footer class="modal-card-foot">
          <button v-for="(button,index) in buttons" :key="'button_' + index" :class="css_class(button)" class="button" @click="click(button, $event)" :disabled="disabled(button)" v-html="button.label"></button>
        </footer>
      </template>
      <template v-else-if="data.text">
        <div class="modal-content">
          <div class="message" :class="data.css_class">
            <header class="message-header">
              <p v-html="data.label"></p>
              <button @click="$emit('close')" class="delete"></button>
            </header>
            <div class="message-body" v-html="data.text"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VsInput from './VsInput.vue'
  export default {
    components: {
      'vs-input': VsInput
    },
    props: {
      data: {
        type: Object,
        default: function () { return {} }
      }
    },
    data: function () {
      return {}
    },
    destroyed: function() {
      // document.documentElement.style.cssText = "overflow: auto";
    },
    computed: {
      buttons: function() {
        if (this.data.buttons) return this.data.buttons;
        let buttons = [];
        if (this.data.resolve) buttons.push({ css_class: this.data.resolve.css_class, label: this.data.resolve.label, promise: this.data.resolve.action });
        if (this.data.reject) buttons.push({ css_class: this.data.reject.css_class, label: this.data.reject.label, promise: this.data.reject.action });
        if (buttons.length == 0) buttons.push({ label: this.$i18n.translate('scaffold', 'buttons.close', 'Close'), cancel: true });
        return buttons;
      }
    },
    methods: {
      click: function(button, e) {
        if (button.promise) button.promise([e, (result) => {
          if (result && (result.data._dialog_ || result.data._message_)) return;
          this.$emit('close');
        }]);
        else if (button.primary) {
          this.$http.indicator = e;
          let params = button.params || {};
          for (let comp of this.data.components) {
            if (comp.options.disabled) continue;
            let value = comp.value;
            if (value == undefined) value = null;
            if (this.data.batch == undefined || comp.batched != '') params[comp.options.column || comp.column] = value;
            comp.options.error = null;
          }
          if (this.data.lock_version) params['lock_version'] = this.data.lock_version;
          if (this.data.batch) params['_batch_'] = Object.values(this.marks);
          let options = { method: button.method || 'GET', url: button.url, headers: { 'vs-id': this.vsid } };
          if (options.method.toLowerCase() == 'get') options['params'] = params;
          else options['data'] = params;
          this.$http(options).then((result) => {
            if (result.data._dialog_ || result.data._message_) return;
            this.$emit('close');
          }).catch((error) => {
            if (error.response.data.conflicts) {
              for (var col in error.response.data.conflicts) {
                if (col == 'lock_version') {
                  this.data.lock_version = error.response.data.conflicts[col];
                  continue;
                }
                for (let comp of this.data.components) {
                  if ((comp.column == col || comp.options.column == col) && comp.value != error.response.data.conflicts[col]) {
                    comp.options.input = true;
                    comp.options.error = this.$i18n.translate('scaffold', 'texts.stale_record', 'Record value was modified') + ': ' + this.$util.truncate(error.response.data.conflicts[col].toString(), 75);
                    break;
                  }
                }
              }
            }
            if (error.response.data.errors) {
              for (var col in error.response.data.errors) {
                for (let comp of this.data.components) {
                  if (comp.column == col || comp.options.column == col) {
                    comp.options.input = true;
                    comp.options.error = error.response.data.errors[col];
                    break;
                  }
                }
              }
            }
          });
        } else if (button.cancel) this.$emit('close');
      },
      css_class: function(button) {
        if (button.css_class) return button.css_class;
        if (button.primary && this.data.components && this.data.components.every((comp) => { return comp.validated }) && !this.data.components.every((comp) => { return comp.batched == '' })) return 'is-link';
      },
      disabled: function(button) {
        if (!button.primary) return false;
        if (this.data.components) return (!this.data.components.every((comp) => { return comp.validated }) || this.data.components.every((comp) => { return comp.batched == '' }));
      },
      close: function(e) {
        this.$emit('close');
        if (this.data.reject) this.data.reject.action(e);
      }
    },
    mounted: function() {
      // document.documentElement.style.cssText = "overflow: hidden";
    }
  }
</script>
<style>
.vs-dialog .message { margin: 0 auto }
.vs-dialog ul {
  list-style-type: disc;
  margin-bottom: 0.5em;
}
</style>
