<template>
  <div v-show="visible && content != null" class="vs-help modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card" :style="{ width: width }">
      <header class="modal-card-head">
        <a class="vs-help-burger" :class="{ 'is-active': burger_active }" @click="burger_active = !burger_active">
          <span class="icon"><i class="fas" :class="burger_active ? 'fa-times' : 'fa-bars'"></i></span>
        </a>
        <p class="modal-card-title noselection" v-html="title"></p>
        <button @click="$emit('close')" class="delete"></button>
      </header>
      <div class="modal-card-body">
        <div class="vs-help-container">
          <aside :class="burger_active ? 'vs-help-aside-active' : ''">
            <div class="vs-help-search field has-addons">
              <div class="control">
                <button @click="refresh(null, $event)" class="button fas fa-home"></button>
              </div>
              <div class="control is-expanded has-icons-left">
                <input @keyup.enter="refresh(query, $event)" v-model="query" class="input" type="text" placeholder="Search">
                <span class="icon is-left">
                  <i class="fas fa-search"></i>
                </span>
              </div>
            </div>
            <div class="vs-help-aside">
              <ul v-if="items">
                <vs-help-item @show="show" v-for="(item,index) in items" :active="active" :data="item" :key="'item_' + index"></vs-help-item>
              </ul>
            </div>
          </aside>
          <div :class="this.title == this.home.title ? 'vs-help-home' : ''" class="vs-help-content content" v-html="content"></div>
        </div>
      </div>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </div>
</template>

<script>
  import VsHelpItem from './help/VsHelpItem.vue'
  export default {
    components: {
      'vs-help-item': VsHelpItem
    },
    props: [ 'visible', 'width' ],
    data: function () {
      return {
        burger_active: false,
        home: {
          title: null,
          content: null
        },
        active: {},
        title: null,
        content: null,
        items: null,
        query: ''
      }
    },
    computed: {
    },
    created: function() {
      this.refresh();
    },
    methods: {
      refresh: function(query, e) {
        let url = '/' + this.$i18n.locale + '/helps/0/fetch';
        if (query && query.length > 0) url = url + '?query=' + query;
        if (this.home.title) this.title = this.home.title;
        if (this.home.content) this.content = this.home.content;
        this.$http.indicator = e;
        this.$http(url).then((result) => {
          this.active = {};
          this.items = result.data.items;
          this.home.title = result.data.title;
          this.home.content = result.data.content;
          this.title = this.home.title;
          this.content = this.home.content;
        }).catch((error) => {});
      },
      show: function(result, caret) {
        this.active = result.pids;
        this.content = result.content;
        this.title = result.title;
        if (!caret) this.burger_active = false;
      }
    }
  }
</script>
<style>
  .vs-help-burger {
    color: #444;
    display: none;
    margin-right: 0.5em;
  }
  .vs-help .modal-card {
    height: calc(100vh - 40px);
    width: calc(100vw - 40px);
    max-width: 1600px;
  }
  .vs-help .modal-card-head {
    padding: 10px;
  }
  .vs-help .modal-card-body {
    display: flex;
    min-height: 75vh;
    padding: 0;
  }
  .vs-help .modal-card-foot {
    padding: 15px;
  }
  .vs-help .panel {
    border-radius: 0;
  }
  .vs-help-container {
    display: flex;
    flex: 1 1 auto;
    position: relative;
  }
  .vs-help-container > aside {
    background-color: #fefefe;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    display: flex;
    flex-direction: column;
  }
  .vs-help-content {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 1em;
    position: relative;
  }
  .vs-help-aside {
    overflow-y: auto;
    width: 225px;
  }
  .vs-help-search {
    border-bottom: 1px solid #ededed;
    margin: 0 !important;
    padding: 0.25em;
    width: 225px;
  }
  .vs-help-search button {
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
  }
  .vs-help-search input {
    background-color: #fbfbfb;
  }
  .vs-help-home::after {
    content: '';
    background-image: url('../../images/wheel-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
    opacity: 0.4;
    z-index: 5;
  }
@media screen and (max-width: 768px) {
  .vs-help-burger {
    display: block;
  }
  .vs-help-container > aside {
    height: 100%;
    position: absolute;
    z-index: 10;
  }
  .vs-help-container > aside {
    display: none;
  }
  .vs-help-container > .vs-help-aside-active {
    display: flex;
  }
}
</style>
