<template>
  <div v-if="visible && scaffold" @mousedown="disable_double_click" @touchstart.stop="swipe_touchstart" @touchmove.stop="swipe_touchmove" @touchend.stop="swipe_touchend" class="vs-view" :class="vs_class" ref="scaffold">
    <div v-if="notitle == false" @dblclick.prevent.stop="row_contextmenu(null, $event)" @contextmenu.prevent.stop="row_contextmenu(null, $event)" class="vs-header">
      <h2 class="title">{{ scaffold.config.label }}<span v-if="Object.keys(marks).length > 0" style="font-size:0.65em"> ({{  Object.keys(marks).length + ' ' + $i18n.translate('scaffold', 'texts.selected', 'selected') }}) <i @click="mark_clear" class="fas fa-times-circle vs-icon-event" style="font-size:0.75em"></i></span></h2>
      <button class="vs-close" v-if="level > 0" @contextmenu.prevent.stop="" @click="$emit('close', $event)"></button>
    </div>
    <div v-if="nocontrols == false" @dblclick.prevent.stop="row_contextmenu(null, $event)" @contextmenu.prevent.stop="row_contextmenu(null, $event)" class="vs-controls">
      <div @dblclick.prevent.stop="" @contextmenu.stop="">
        <div class="field has-addons">
          <div v-if="'recycle' in scaffold.config" class="control">
            <button @click="toggle_recyclebin" :disabled="scaffold.config.recycle.empty" :class="{ 'is-focused': recyclebin.visible }" class="button is-small fas fa-recycle"></button>
          </div>
          <div v-if="false" class="control">
            <button @click="config.visible = !config.visible" class="button is-small fas fa-user-cog"></button>
          </div>
          <div v-if="scaffold.config.search.text.length > 0" class="control has-icons-left has-icons-right vs-search-text">
            <input v-model="searching.text" @input="search_debounce" :placeholder="$i18n.translate('scaffold', 'texts.text_search', 'Text search')" type="text" class="input is-small">
            <span @click="search_text_reset(true, $event)" :class="searching.text.length == 0 ? '' : 'vs-icon-event'" class="icon is-small is-left">
              <i class="fas" :class="(searching.text.length == 0) ? 'fa-search' : 'fa-arrow-left'" :style="{ color: (searching.text.length == 0) ? '#dbdbdb' : '#4a4a4a' }"></i>
            </span>
            <span @click="toggle_search" class="vs-icon-event icon is-small is-right">
              <i class="fas" :class="searching.visible ? 'fa-chevron-up' : ' fa-chevron-down'"></i>
            </span>
          </div>
        </div>
      </div>
      <div @dblclick.prevent.stop="" @contextmenu.stop="" class="vs-pagination vs-pagination-top">
        <div class="field has-addons">
          <div class="control">
            <button class="button is-small fas fa-fast-backward" :disabled="scaffold.pagination.page <= 1" @click="goto(0, $event)"></button>
          </div>
          <div class="control">
            <button class="button is-small fas fa-backward" :disabled="scaffold.pagination.page <= 1" @click="goto(scaffold.pagination.page - 1, $event)"></button>
          </div>
          <div v-if="$util.shared.screen.width > 550" class="control">
            <div :class="pagination_page_options.length == 1 ? 'disabled' : ''" class="select is-small">
              <select :disabled="pagination_page_options.length == 1" v-model.number="scaffold.pagination.page" @change="goto(scaffold.pagination.page, $event)">
                <option :selected="option.value == scaffold.pagination.page" :value="option.value" v-for="option in pagination_page_options" :key="option.value">{{ option.text }}</option>
              </select>
            </div>
          </div>
          <div class="control">
            <button class="button is-small fas fa-forward" :disabled="scaffold.pagination.total / scaffold.pagination.size <= scaffold.pagination.page" @click="goto(scaffold.pagination.page + 1, $event)"></button>
          </div>
          <div class="control">
            <button class="button is-small fas fa-fast-forward" :disabled="scaffold.pagination.total / scaffold.pagination.size <= scaffold.pagination.page" @click="goto(-1, $event)"></button>
          </div>
          <div v-if="$util.shared.screen.width > 550" class="control">
            <div class="select is-small">
              <select class="vs-pagination-size" v-model.number="scaffold.pagination.size" @change="goto(scaffold.pagination.page, $event)">
                <option v-for="option in pagination_size_options" :key="option">{{ option }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vs-table-header">
      <div v-if="searching.visible" @contextmenu.stop="" :class="level % 2 == 1 ? 'vs-even' : 'vs-odd'" class="vs-search vs-view">
        <div class="vs-header">
          <h2 class="title is-4">{{ $i18n.translate('scaffold', 'texts.advanced_search', 'Advanced search') }}</h2>
          <button class="vs-close" @click="searching.visible = false"></button>
        </div>
        <div class="vs-panel">
          <template v-for="col in search_columns">
            <div v-if="col in searching.columns" class="field is-horizontal" :class="'vs-search-' + col" :key="col">
              <div class="field-label is-normal">
                <label class="label">{{ scaffold.config.columns[col].label }}</label>
              </div>
              <div class="field-body">
                <div class="field is-grouped">
                  <div class="control vs-search_operator">
                    <div class="select is-fullwidth">
                      <select v-model="searching.columns[col].operator">
                        <option v-for="op in searching.operators[col]" :value="op.value" :key="op.label">{{ op.label }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="field is-grouped is-grouped-multiline">
                    <div class="control">
                      <div v-if="scaffold.config.columns[col].search_ui == 'checkbox'" class="select is-fullwidth">
                        <select v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0">
                          <option value="">{{ $i18n.translate('scaffold', 'select.placeholder', '- select -') }}</option>
                          <option value="true">{{ $i18n.translate('scaffold', 'texts.true', 'True') }}</option>
                          <option value="false">{{ $i18n.translate('scaffold', 'texts.false', 'False') }}</option>
                        </select>
                      </div>
                      <div v-else-if="scaffold.config.columns[col].search_ui == 'select'" class="select is-fullwidth">
                        <select v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0">
                          <option value="">{{ $i18n.translate('scaffold', 'select.placeholder', '- select -') }}</option>
                          <option v-for="(label, value) in scaffold.config.columns[col].options.select" :value="value" :key="value">{{ label }}</option>
                        </select>
                      </div>
                      <vs-number v-else-if="scaffold.config.columns[col].search_ui == 'duration'" v-model="searching.columns[col].value1" spinner="true"></vs-number>
                      <input v-else-if="scaffold.config.columns[col].search_ui == 'date'" v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="date">
                      <input v-else-if="scaffold.config.columns[col].search_ui == 'datetime'" v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="datetime-local">
                      <input v-else-if="scaffold.config.columns[col].search_ui == 'time'" v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="time">
                      <input v-else v-model="searching.columns[col].value1" @keyup.enter="search" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" :type="scaffold.config.columns[col].search_ui == 'number' ? 'number' : 'text'">
                    </div>
                    <div v-if="searching.columns[col].operator == 'between'" class="control">
                      <vs-duration v-if="scaffold.config.columns[col].search_ui == 'duration'" v-model="searching.columns[col].value2"></vs-duration>
                      <input v-else-if="scaffold.config.columns[col].search_ui == 'date'" v-model="searching.columns[col].value2" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="date">
                      <input v-else-if="scaffold.config.columns[col].search_ui == 'datetime'" v-model="searching.columns[col].value2" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="datetime-local">
                      <input v-else-if="scaffold.config.columns[col].search_ui == 'time'" v-model="searching.columns[col].value2" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="time">
                      <input v-else v-model="searching.columns[col].value2" @keyup.enter="search" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" :type="scaffold.config.columns[col].search_ui == 'number' ? 'number' : 'text'">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-if="search_optional_columns.length > 0">
            <h4 class="is-size-5">{{ $i18n.translate('scaffold', 'texts.further_options', 'Further options') }} (<a @click="searching.optional = !searching.optional">{{ searching.optional ? $i18n.translate('scaffold', 'buttons.hide', 'Hide') : $i18n.translate('scaffold', 'buttons.show', 'Show') }}</a>)</h4>
            <div v-if="searching.optional">
              <div v-for="col in search_optional_columns" class="field is-horizontal" :class="'vs-search-' + col" :key="'search_optional_columns_' + col">
                <div class="field-label is-normal">
                  <label class="label">{{ scaffold.config.columns[col].label }}</label>
                </div>
                <div class="field-body">
                  <div class="field is-grouped">
                    <div class="control vs-search_operator">
                      <div class="select is-fullwidth">
                        <select v-model="searching.columns[col].operator">
                          <option v-for="op in searching.operators[col]" :value="op.value" :key="'search_operator_' + col + '_' + op.value">{{ op.label }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="field is-grouped is-grouped-multiline">
                      <div class="control">
                        <div v-if="scaffold.config.columns[col].search_ui == 'checkbox'" class="select is-fullwidth">
                          <select v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0">
                            <option value="">{{ $i18n.translate('scaffold', 'select.placeholder', '- select -') }}</option>
                            <option value="true">{{ $i18n.translate('scaffold', 'texts.true', 'True') }}</option>
                            <option value="false">{{ $i18n.translate('scaffold', 'texts.false', 'False') }}</option>
                          </select>
                        </div>
                        <div v-else-if="scaffold.config.columns[col].search_ui == 'select'" class="select is-fullwidth">
                          <select v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0">
                            <option value="">{{ $i18n.translate('scaffold', 'select.placeholder', '- select -') }}</option>
                            <option v-for="(label, value) in scaffold.config.columns[col].options.select" :value="value" :key="'search_select_option_' + value">{{ label }}</option>
                          </select>
                        </div>
                        <vs-duration v-else-if="scaffold.config.columns[col].search_ui == 'duration'"></vs-duration>
                        <input v-else-if="scaffold.config.columns[col].search_ui == 'date'" v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="date">
                        <input v-else-if="scaffold.config.columns[col].search_ui == 'datetime'" v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="datetime-local">
                        <input v-else-if="scaffold.config.columns[col].search_ui == 'time'" v-model="searching.columns[col].value1" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="time">
                        <input v-else v-model="searching.columns[col].value1" @keyup.enter="search" :disabled="['is_null', 'is_not_null'].indexOf(searching.columns[col].operator) >= 0" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" :type="scaffold.config.columns[col].search_ui == 'number' ? 'number' : 'text'">
                      </div>
                      <div v-if="searching.columns[col].operator == 'between'" class="control">
                        <vs-duration v-if="scaffold.config.columns[col].search_ui == 'duration'"></vs-duration>
                        <input v-else-if="scaffold.config.columns[col].search_ui == 'date'" v-model="searching.columns[col].value2" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="date">
                        <input v-else-if="scaffold.config.columns[col].search_ui == 'datetime'" v-model="searching.columns[col].value2" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="datetime-local">
                        <input v-else-if="scaffold.config.columns[col].search_ui == 'time'" v-model="searching.columns[col].value2" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" type="time">
                        <input v-else v-model="searching.columns[col].value2" @keyup.enter="search" class="input" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false" :type="scaffold.config.columns[col].search_ui == 'number' ? 'number' : 'text'">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vs-buttons">
          <button class="button is-link" @click="search">{{ $i18n.translate('scaffold', 'buttons.search', 'Search') }}</button>
          <button class="button is-danger" @click="search_reset(true, $event)">{{ $i18n.translate('scaffold', 'buttons.reset', 'Reset') }}</button>
          <button class="button" @click="searching.visible = false">{{ $i18n.translate('scaffold', 'buttons.close', 'Close') }}</button>
        </div>
      </div>
      <div v-if="recyclebin.visible" :class="level % 2 == 1 ? 'vs-even' : 'vs-odd'" class="vs-recyclebin vs-view">
        <vs-scaffold @refresh="refresh(false)" @close="recyclebin.visible = false" :level="level + 1" :data="recyclebin.scaffold"></vs-scaffold>
      </div>
      <div v-if="config.visible" :class="level % 2 == 1 ? 'vs-even' : 'vs-odd'" class="vs-config">
        Config
      </div>
    </div>
    <div class="vs-table-container">
      <table @dblclick.prevent.stop="row_contextmenu(null, $event)" @contextmenu.prevent.stop="row_contextmenu(null, $event)">
        <thead ref="header" @dblclick.prevent.stop="row_contextmenu(null, $event)" @contextmenu.prevent.stop="row_contextmenu(null, $event)">
          <tr class="vs-table-header">
            <th v-if="scaffold.config.actions.mark.enabled" @contextmenu.prevent.stop="mark_contextmenu($event)" @dblclick.prevent.stop="" @click="mark_toggle" class="vs-mark"><input :indeterminate.prop="markall_indeterminate_state" :checked="markall_checked_state" @click="mark_all" type="checkbox"></th>
            <th :style="scaffold.config.columns[col].style" :class="['vs-th-' + col, scaffold.config.columns[col].sortable == null ? 'vs-sort-disabled' : '', scaffold.config.columns[col].css_class, scaffold.config.columns[col]._highlight_ ? 'vs-highlight-light' : '']" v-for="(col,col_index) in visible_columns" @todocontextmenu.prevent.stop="search_contextmenu(col, $event)" @touchstart.stop="sort(col, $event, true)" @click.prevent.stop="sort(col, $event)" :key="'th_' + col_index">
              <div class="has-icons-right">
                <label>{{ scaffold.config.columns[col].label }}</label>
                <span class="icon is-small is-right">
                  <i class="fas" :class="sort_css_class(col)"></i>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!searching.visible && search_params.length > 0" class="vs-row vs-empty">
            <td :colspan="visible_columns.length + 1" style="position:relative">
              <a style="position:absolute;left:0.25rem;top:50%;transform:translateY(-50%)" @click="search_reset(true, $event)">{{ $i18n.translate('scaffold', 'buttons.reset', 'Reset') }}</a>
              <div v-for="params in search_params" :key="'search_params_' + params"><b>{{ params['label'] }}</b> {{ params['operator'] }}{{ params['value1'] ? " '" + params['value1'] + "'" : ''}}{{ params['value2'] ? (" & '" + params['value2'] + "'") : '' }}</div>
            </td>
          </tr>
          <tr v-if="panel" class="vs-row vs-panel" ref="tr_panel">
            <td class="vs-nesting" :colspan="visible_columns.length + 1">
              <vs-panel @close="panel_close" :data="panel" :marks="marks" :vsid="vsid" :class="level % 2 == 1 ? 'vs-even' : 'vs-odd'" key="panel"></vs-panel>
            </td>
          </tr>
          <template v-for="item in items">
            <tr :style="{ display: !item['_panel_'] || item['_panel_'].nesting ? '' : 'none'}" :class="[item._highlight_ ? 'vs-highlight' : '', item._mark_ ? 'vs-marked' : '']" class="vs-row" @dblclick.prevent.stop="row_contextmenu(item, $event)" @contextmenu.prevent.stop="row_contextmenu(item, $event)" :key="'tr_item_' + item.id" :ref="'tr_item_' + item.id">
              <td v-if="scaffold.config.actions.mark.enabled" class="vs-mark"><input @click="mark(item)" @dblclick.prevent.stop="" :disabled="item._mark_ == undefined" :checked="item._mark_" type="checkbox"></td>
              <td :class="['vs-td-' + col, scaffold.config.columns[col].css_class, item[col].value == null ? 'vs-center' : '']" :style="item[col].style" v-for="(col,col_index) in visible_columns" :key="'td_item_' + item.id + '_' + col_index">
                <template v-if="item[col].html">
                  <div v-html="item[col].html"></div>
                </template>
                <template v-else-if="item[col].tooltip">
                  <a v-if="scaffold.config.columns[col].association" @click.prevent="association(item, col, $event)" v-html="item[col].link ? item[col].link : item[col].value"></a>
                  <span v-else class="vs-tooltip-target" @mouseover="tooltip_start(item[col].tooltip, $event)" @mouseleave="tooltip_stop" v-html="item[col].value"></span>
                </template>
                <template v-else-if="scaffold.config.columns[col].association">
                  <a v-if="item[col].link" @click.prevent="association(item, col, $event)" v-html="typeof(item[col].link) == 'boolean' ? item[col].value : item[col].link"></a>
                  <span v-else-if="item[col].html_safe" v-html="item[col].value"></span>
                  <span v-else-if="item[col].value != null">{{ item[col].value }}</span>
                  <span v-else>-</span>
                </template>
                <template v-else>
                  <vs-output :data="item[col]" :options="scaffold.config.columns[col].options" :ui="scaffold.config.columns[col].ui"></vs-output>
                </template>
              </td>
            </tr>
            <tr v-if="item._panel_" class="vs-row vs-panel" :ref="'tr_panel_' + item.id" :key="'tr_panel_' + item.id">
              <td class="vs-nesting" :colspan="visible_columns.length + 1">
                <vs-panel @close="panel_close(item)" :data="item._panel_" :marks="marks" :vsid="vsid" :class="level % 2 == 1 ? 'vs-even' : 'vs-odd'"></vs-panel>
              </td>
            </tr>
            <tr v-else-if="item._scaffold_" class="vs-row vs-scaffold" :ref="'tr_scaffold_' + item.id" :key="'tr_scaffold_' + item.id">
              <td class="vs-nesting" :colspan="visible_columns.length + 1">
                <vs-scaffold @refresh="refresh" @close="nesting_close(item.id, ...arguments)" :level="level + 1" :data="item._scaffold_"></vs-scaffold>
              </td>
            </tr>
          </template>
          <tr v-if="scaffold.items.length == 0" class="vs-row vs-empty">
            <td :colspan="visible_columns.length + 1">{{ $i18n.translate('scaffold', 'texts.nothing', 'Nothing') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div @contextmenu.prevent.stop="row_contextmenu(null, $event)" class="vs-footer">
      <div class="vs-pagination vs-pagination-bottom">
        <div class="field has-addons">
          <div v-if="level > 0" class="control">
            <button class="button vs-close-footer is-small fas fa-times" @click="$emit('close', $event)"></button>
          </div>
          <div class="control">
            <button class="button is-small fas fa-fast-backward" :disabled="scaffold.pagination.page <= 1" @click="goto(0, $event)"></button>
          </div>
          <div class="control">
            <button class="button is-small fas fa-backward" :disabled="scaffold.pagination.page <= 1" @click="goto(scaffold.pagination.page - 1, $event)"></button>
          </div>
          <div class="control">
            <div :class="pagination_page_options.length == 1 ? 'disabled' : ''" class="select is-small">
              <select :disabled="pagination_page_options.length == 1" v-model.number="scaffold.pagination.page" @change="goto(scaffold.pagination.page, $event)">
                <option :selected="option.value == scaffold.pagination.page" :value="option.value" v-for="(option,option_index) in pagination_page_options" :key="'pagination_option_' + option_index">{{ option.text }}</option>
              </select>
            </div>
          </div>
          <div class="control">
            <button class="button is-small fas fa-forward" :disabled="scaffold.pagination.total / scaffold.pagination.size <= scaffold.pagination.page" @click="goto(scaffold.pagination.page + 1, $event)"></button>
          </div>
          <div class="control">
            <button class="button is-small fas fa-fast-forward" :disabled="scaffold.pagination.total / scaffold.pagination.size <= scaffold.pagination.page" @click="goto(-1, $event)"></button>
          </div>
          <div class="control">
            <div class="select is-small">
              <select class="vs-pagination-size" v-model.number="scaffold.pagination.size" @change="goto(scaffold.pagination.page, $event)">
                <option v-for="option in pagination_size_options" :key="'pagination_size_' + option">{{ option }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <h2 v-if="level > 0" class="title is-5">{{ scaffold.config.label }}<span style="font-size:0.65em">{{ Object.keys(marks).length > 0 ? ' (' + Object.keys(marks).length + ' ' + $i18n.translate('scaffold', 'texts.selected', 'selected') + ')' : ''}}</span></h2>
    </div>
    <vs-contextmenu v-if="contextmenu.visible" :data="contextmenu" :vsid="vsid" @close="contextmenu_close"></vs-contextmenu>
    <vs-tooltip v-if="tooltip.visible" :data="tooltip"></vs-tooltip>
  </div>
</template>

<script>
  import VsContextMenu from './VsContextMenu.vue'
  import VsDuration from './VsDuration.vue'
  import VsNumber from './VsNumber.vue'
  import VsInput from './VsInput.vue'
  import VsOutput from './VsOutput.vue'
  import VsPanel from './VsPanel.vue'
  import VsTooltip from './VsTooltip.vue'
  export default {
    name: 'vs-scaffold',
    components: {
      'vs-contextmenu': VsContextMenu,
      'vs-duration': VsDuration,
      'vs-number': VsNumber,
      'vs-input': VsInput,
      'vs-output': VsOutput,
      'vs-panel': VsPanel,
      'vs-tooltip': VsTooltip
    },
    props: {
      data: {
        type: Object,
        default: function () { return {} }
      },
      level: {
        type: Number,
        default: function() { return 0 }
      },
      notitle: {
        type: Boolean,
        default: function() { return false }
      },
      nocontrols: {
        type: Boolean,
        default: function() { return false }
      },
      visible: {
        type: Boolean,
        default: function() { return true }
      },
    },
    data: function () {
      return {
        vsid: null,
        channel: null,
        config: {
          visible: false,
          data: {}
        },
        contextmenu: {
          show: function(x, y, items, target, on_close) {
            this.on_close = on_close;
            this.items = items;
            this.target = target;
            this.visible = true;
            this.x = x;
            this.y = y;
          },
          on_close: null,
          items: [],
          target: null,
          visible: false,
          x: 0,
          y: 0
        },
        marks: {},
        panel: null,
        recyclebin: {
          scaffold: null,
          visible: false
        },
        resetting: true,
        scaffold: null,
        searching: {
          text: null,
          columns: null,
          operators: null,
          optional: false,
          visible: false
        },
        sorting: {},
        swipe: {},
        tooltip: {
          components: null,
          text: null,
          fetch: false,
          timer: null,
          visible: false,
          x: null,
          y: null
        }
      }
    },
    watch: {
      data: function (value) {
        this.refresh();
      }
    },
    destroyed: function() {
      this.$http.interceptors.response.eject(this.interceptor);
    },
    created: function() {
      this.search_debounce = this.$util.debounce(() => { this.search() }, 1000);
      this.vsid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + (new Date().getTime());
      this.interceptor = this.$http.interceptors.response.use((response) => {
        if (this.vsid == response.headers['vs-id']) {
          // unmark
          if (response.data._unmark_) this.marks = {};
          // refresh list
          if (response.data._refresh_) {
            var params = {
              '_nesting_': this.scaffold.nesting,
              '_pagination_': { page: this.scaffold.pagination.page, size: this.scaffold.pagination.size },
              '_searching_': this.scaffold.searching,
              '_sorting_': this.scaffold.sorting
            };
            if (response.data.id) params['id'] = response.data.id;
            this.$http({
              url: this.scaffold.config.route_path,
              headers: { 'vs-id': this.vsid },
              params: params
            }).catch((error) => {});
            if (response.data._refresh_ == 'parent') this.$emit('refresh');
          }
          // refresh row id (eg. from closing nested table or after a row update)
          if (response.data._id_) {
            for (let item of this.scaffold.items) {
              if (item.id == response.data._id_) {
                Object.assign(item, response.data.item);
                break;
              }
            }
          }
          // components panel
          if (response.data._panel_) {
            if (response.data._panel_.id == null) {
              this.panel = response.data._panel_;
              this.$nextTick(() => {
                // scroll intoview
                var e = this.$refs['tr_panel'];
                if (e) e.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
              });
            } else {
              for (let item of this.scaffold.items) {
                if (item.id == response.data._panel_.id) {
                  item['_panel_'] = response.data._panel_;
                  this.$nextTick(() => {
                    // scroll intoview
                    let e = this.$refs['tr_panel_' + item.id][0];
                    if (e) e.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                  });
                  break;
                }
              }
            }
          }
          // scaffold data
          if (response.data._scaffold_) {
            this.scaffold = response.data._scaffold_;
            for (let sort_col in this.sorting) if (!this.scaffold.sorting[sort_col]) this.sorting[sort_col] = 0;
            if (this.resetting) this.reset();
            else for (let item of this.scaffold.items) if (item.id in this.marks) item._mark_ = true;
            this.$emit('loaded', 'scaffold');
          }
          // exec javascript snippet
          if (response.data._execjs_) this.$nextTick(() => { eval(response.data._execjs_) });
        }
        return response;
      }, (error) => {
        if (error.response) {
          let header = this.$i18n.translate('scaffold', 'errors.' + error.response.status.toString() + '_header');
          let body = this.$i18n.translate('scaffold', 'errors.' + error.response.status.toString() + '_body');
          if (header && body) this.$dialog.alert(header, body, 'is-danger');
        } else if (error.request) {
          // client connection error code
          if (error.request.status == 0) this.$dialog.alert(this.$i18n.translate('scaffold', 'errors.connection_header', 'Connection error'), this.$i18n.translate('scaffold', 'errors.connection_body', 'The connection to the server cannot be reached at the moment.  Please check your Internet connection or try again later.'), 'is-danger');
        }
        return Promise.reject(error);
      });
      this.refresh();
    },
    computed: {
      items: function() {
        if (!('_order_' in this.scaffold.sorting) || this.scaffold.sorting['_order_'].length == 0 || this.scaffold.sorting['_default_']) return this.scaffold.items;
        let sorted_items = this.scaffold.items.slice().sort((a, b) => {
          for (let col of this.scaffold.sorting['_order_']) {
            if (!(col in a) || !(col in b)) continue;
            let asc = (this.scaffold.sorting[col].indexOf('down') >= 0);
            let a_sort_value = a[col].sort_value === undefined ? a[col].value : a[col].sort_value;
            let b_sort_value = b[col].sort_value == undefined ? b[col].value : b[col].sort_value;
            if (a_sort_value > b_sort_value || a_sort_value == null) return (asc ? 1 : -1);
            else if (a_sort_value < b_sort_value || b_sort_value == null) return (asc ? -1 : 1);
          }
          return 0;
        });
        return sorted_items;
      },
      markall_checked_state: function() {
        var checked_once = false, checked_all = true;
        for (let item of this.scaffold.items) {
          if (item._mark_) checked_once = true;
          else if (item._mark_ == false) checked_all = false;
        }
        return checked_once && checked_all;
      },
      markall_indeterminate_state: function() {
        var unchecked = 0;
        var checked = 0;
        for (let item of this.scaffold.items) {
          if (item._mark_ == undefined) continue;
          else if (item._mark_) checked++;
          else unchecked++;
        }
        return !(unchecked == 0 || checked == 0);
      },
      pagination_page_options: function() {
        var options = [];
        for (var i = 0; i <= Math.ceil(this.scaffold.pagination.total / this.scaffold.pagination.size); i++) {
          if (i > 0 && i == Math.ceil(this.scaffold.pagination.total / this.scaffold.pagination.size)) break;
          var start = (i * this.scaffold.pagination.size) + 1;
          var stop = (i + 1) * this.scaffold.pagination.size;
          if (start > this.scaffold.pagination.total) start = this.scaffold.pagination.total;
          if (stop > this.scaffold.pagination.total) stop = this.scaffold.pagination.total;
          options.push({ text: this.scaffold.pagination.text.replace('%{page}', i + 1).replace('%{start}', start).replace('%{stop}', stop).replace('%{total}', this.scaffold.pagination.total), value: i + 1 });
        }
        return options;
      },
      pagination_size_options: function() {
        var options = [ 15, 20, 25, 30, 50, 75, 100 ];
        if (options.indexOf(this.scaffold.pagination.size) < 0) options.push(this.scaffold.pagination.size);
        return options.sort((a, b) => { return a - b });
      },
      search_columns: function() {
        var checkboxes = [], numbers = [], strings = [], dates = [];
        for (let col of this.scaffold.config.search.columns) {
          if (this.scaffold.config.search.optional_columns.indexOf(col) >= 0) continue;
          if (!!!this.scaffold.config.columns[col].show_nested_column && this.scaffold.nesting && this.scaffold.config.columns[col].association && this.scaffold.nesting.parent == this.scaffold.config.columns[col].association.model) continue;
          if (this.scaffold.config.columns[col].search_ui == 'checkbox' || this.scaffold.config.columns[col].search_ui == 'select') checkboxes.push(col);
          else if (this.scaffold.config.columns[col].search_ui == 'number') numbers.push(col);
          else if (['date', 'datetime', 'duration', 'time'].indexOf(this.scaffold.config.columns[col].search_ui) >= 0) dates.push(col);
          else strings.push(col);
        }
        return checkboxes.sort().concat(strings.sort()).concat(numbers.sort()).concat(dates.sort());
      },
      search_optional_columns: function() {
        var checkboxes = [], numbers = [], strings = [], dates = [];
        for (let col of this.scaffold.config.search.optional_columns) {
          if (this.scaffold.nesting && this.scaffold.config.columns[col].association && this.scaffold.nesting.parent == this.scaffold.config.columns[col].association.model) continue;
          if (this.scaffold.config.columns[col].search_ui == 'checkbox' || this.scaffold.config.columns[col].search_ui == 'select') checkboxes.push(col);
          else if (this.scaffold.config.columns[col].search_ui == 'number') numbers.push(col);
          else if (['date', 'datetime', 'duration', 'time'].indexOf(this.scaffold.config.columns[col].search_ui) >= 0) dates.push(col);
          else strings.push(col);
        }
        return checkboxes.sort().concat(strings.sort()).concat(numbers.sort()).concat(dates.sort());
      },
      search_params: function() {
        var params = [];
        for (var col in this.scaffold.searching.columns) {
          var search_col = this.scaffold.searching.columns[col];
          let value1 = search_col['value1'];
          let value2 = search_col['value2'];
          if (['is_null', 'is_not_null'].indexOf(search_col['operator']) >= 0) {
            value1 = null;
            value2 = null;
          }
          else if (search_col['value1'] == '') continue;
          else if (this.scaffold.config.columns[col].search_ui == 'datetime') {
            if (value1 && value1.length > 0) value1 = new Date(value1);
            if (value2 && value2.length > 0) value2 = new Date(value2);
          }
          else if (this.scaffold.config.columns[col].search_ui == 'select') {
            value1 = this.scaffold.config.columns[col].options.select[value1];
            value2 = null;
          }
          params.push({ label: this.scaffold.config.columns[col].label, operator: this.$i18n.translate('scaffold', 'search.' + search_col['operator']).toLowerCase(), value1: value1, value2: value2 });
        }
        return params;
      },
      visible_columns: function() {
        // order columns based on user preferences
        var list_columns = [];
        for (let col of this.scaffold.config.list.columns) {
          // hide nested column
          if (this.scaffold.nesting && this.scaffold.config.columns[col].association && this.scaffold.nesting.parent == this.scaffold.config.columns[col].association.model && !this.scaffold.config.columns[col].show_nested_column) continue;
          list_columns.push(col);
        }
        return list_columns;
      },
      vs_class: function() {
        var css_class = this.scaffold.config.route_key + '-view';
        if (this.level == 0) return css_class;
        css_class = css_class + ' ' + (this.level % 2 == 0 ? 'vs-even' : 'vs-odd');
        return css_class;
      },
    },
    methods: {
      association: function(item, col, e) {
        if (!this.scaffold.config.columns[col].association) return;
        let url = this.scaffold.config.columns[col].association.route_path || item['_routes_'][col];
        let params = {};
        this.$http.indicator = e;
        if  (this.scaffold.config.columns[col].association.multiple) {
          params['_nesting_'] = { parent: this.scaffold.config.model, parent_id: item.id, association: col };
          params['_pagination_'] = { page: 1 };
          if (item._scaffold_) item._scaffold_ = { url: url, params: params };
          else {
            this.$http({ url: url, params: params }).then((result) => {
              if (result.data._scaffold_) item._scaffold_ = { url: url, params: params, prefetch: { scaffold: result.data._scaffold_, execjs: result.data._execjs_} };
            }).catch((error) => {});
          }
        } else {
          url = url + '/' + item[col].id;
          params['_id_'] = item.id;
          this.$http({
            url: url,
            headers: { 'vs-id': this.vsid },
            params: params
          }).catch((error) => {});
        }
      },
      panel_close: function(item) {
        if (item == null) this.panel = null;
        else item._panel_ = null;
        this.$nextTick(() => {
          if (item) this.$refs['tr_item_' + item.id][0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        });
      },
      nesting_close: function(id, e) {
        this.$http.indicator = e;
        this.$http({
          url: this.scaffold.config.route_path,
          headers: { 'vs-id': this.vsid },
          params: { 'id': id }
        }).catch((error) => {});
      },
      refresh: function(reset = true) {
        if (!this.data || !this.data.url || this.data.url == '') return;
        if (this.data.prefetch) {
          this.scaffold = this.data.prefetch.scaffold;
          if (reset) this.reset();
          this.$nextTick(() => {
            if (this.data.prefetch.execjs) eval(this.data.prefetch.execjs);
            delete this.data.prefetch;
            this.$refs.scaffold.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          });
        } else {
          this.resetting = reset;
          console.log('refreshing: ', reset);
          this.$http({
            url: this.data.url,
            headers: { 'vs-id': this.vsid },
            params: this.data.params
          }).catch((error) => {});
        }
      },
      toggle_recyclebin: function(e) {
        e.target.blur();
        if (this.recyclebin.visible) this.recyclebin.visible = false;
        else {
          this.$http.indicator = e;
          this.$http({ url: this.scaffold.config.route_path + '/recycle_list', params: { '_nesting_': this.scaffold.nesting }}).then((response) => {
            if (response.data._scaffold_) {
              this.recyclebin.scaffold  = { url: this.scaffold.config.route_path + '/recycle_list', prefetch: { scaffold: response.data._scaffold_, execjs: response.data._execjs_ } };
              this.recyclebin.visible = true;
            }
          }).catch((error) => {});
        }
      },
      toggle_search: function(e) {
        e.target.blur();
        this.searching.visible = !this.searching.visible
      },
      reset: function() {
        this.resetting = false;
        this.panel = null;
        this.recyclebin.visible = false;
        this.marks = {};
        this.search_reset();
        this.search_text_reset();
      },
      mark: function(item) {
        if (item._mark_) {
          delete this.marks[item.id];
          item._mark_ = false;
        } else {
          item._mark_ = true;
          this.marks[item.id] = { id: item.id, label: item._label_, lock_version: item._lock_version_ };
        }
      },
      contextmenu_close: function() {
        if (this.contextmenu.on_close) this.contextmenu.on_close();
        this.contextmenu.visible = false;
      },
      disable_double_click: function(e) {
        // this is to prevent 'select all' with Chrome on double clicks
        if (e.detail > 1) e.preventDefault();
      },
      goto: function(page, e) {
        if (!e) document.activeElement.blur();
        this.$http.indicator = e;
        this.$http({
          url: this.scaffold.config.route_path,
          headers: { 'vs-id': this.vsid },
          params: {
            '_nesting_': this.scaffold.nesting,
            '_pagination_': { page: page, size: this.scaffold.pagination.size },
            '_searching_': this.scaffold.searching,
            '_sorting_': this.scaffold.sorting
          }
        }).catch((error) => {});
      },
      mark_all: function(e) {
        for (let item of this.scaffold.items) {
          if (item._mark_ == undefined) continue;
          item._mark_ = e.target.checked;
          if (item._mark_) this.marks[item.id] = { id: item.id, label: item._label_, lock_version: item._lock_version_ };
          else delete this.marks[item.id];
        }
      },
      mark_clear: function() {
        for (let item of this.scaffold.items) {
          if (item._mark_ == undefined) continue;
          item._mark_ = false;
        }
        this.marks = {};
      },
      mark_contextmenu: function(e) {
        let items = [];
        items.push({ header: 'Selections', background: '#666' });
        let select_all = () => {
          for (let item of this.scaffold.items) {
            if (item._mark_ == undefined) continue;
            item._mark_ = true;
            this.marks[item.id] = { id: item.id, label: item._label_, lock_version: item._lock_version_ };
          }
        }
        items.push({ label: 'Select all', icon: 'fa-check-square', perform: select_all });
        let deselect_all = () => {
          for (let item of this.scaffold.items) {
            if (item._mark_ == undefined) continue;
            item._mark_ = false;
            delete this.marks[item.id]
          }
        }
        items.push({ label: 'Deselect all', icon: 'fa-square', perform: deselect_all });
        let invert_selections = () => {
          for (let item of this.scaffold.items) {
            if (item._mark_ == undefined) continue;
            if (item._mark_) {
              item._mark_ = false;
              delete this.marks[item.id];
            } else {
              item._mark_ = true;
              this.marks[item.id] = { id: item.id, label: item._label_, lock_version: item._lock_version_ };
            }
          }
        }
        items.push({ label: 'Invert selections', icon: 'fa-retweet', perform: invert_selections });
        this.contextmenu.show(e.clientX, e.clientY, items);
      },
      mark_toggle: function(e) {
        if (e.target.tagName != 'TH') return;
        for (let item of this.scaffold.items) {
          if (item._mark_ == undefined) continue;
          if (item._mark_) {
            item._mark_ = false;
            delete this.marks[item.id];
          } else {
            item._mark_ = true;
            this.marks[item.id] = { id: item.id, label: item._label_, lock_version: item._lock_version_ };
          }
        }
      },
      row_contextmenu: function(row, e) {
        if (this.contextmenu.on_close) this.contextmenu.on_close();
        // if (row == null && this.panel) return;
        var marks = Object.values(this.marks);
        if (row) row._highlight_ = true;
        var types = { batch: [], collection: [], member: [], security: [] };
        for (let action_name in this.scaffold.config.actions) {
          let action = Object.assign({}, this.scaffold.config.actions[action_name]);
          action['name'] = action_name;
          if (marks.length > 0 && action.type == 'member' && action.batch) types.batch.push(action);
          if (!action.type || (!row && action.type == 'member')) continue;
          let tmp = Object.assign({}, action);
          tmp.batch = false;
          if (row && action.type == 'member' && !row['_actions_'][action_name]) tmp.enabled = false;
          if (row || action.type == 'collection') types[action.type].push(tmp);
        }
        var menus = [];
        for (let type in types) {
          if (types[type].length == 0) continue;
          if (type == 'batch') menus.push({ header: this.$i18n.translate('scaffold', 'batch.label', 'Batch') });
          else if (type == 'collection') menus.push({ header: this.scaffold.config.contextmenu_label || this.scaffold.config.label });
          else if (type == 'member') menus.push({ header: this.$i18n.translate('scaffold', 'texts.selection', 'Selection') });
          else if (type == 'security') menus.push({ header: this.$i18n.translate('scaffold', 'texts.security', 'Security') });
          let actions = types[type].sort((a,b) => { return a.order - b.order });
          for (let action of actions) {
            let disabled = (row && (type == 'member' || action.member) ? !row['_actions_'][action.name] : !action.enabled);
            if (action.name == 'create' && this.panel) disabled = true;
            if (disabled && action.hidden) continue;
            let menu_item = { name: action.name, label: action.label, disabled: disabled, icon: action.icon };
            menu_item.action = { type: type, confirm: action.confirm, confirm_text: action.confirm_text, method: action.method || 'GET', url: action.url, headers: { 'vs-id': this.vsid }, params: { '_nesting_': this.scaffold.nesting } };
            if (action.upload) menu_item.upload = true;
            else if (action.upload_multiple) menu_item.upload_multiple = true;
            if (type == 'batch') menu_item.action.params['_batch_'] = (menu_item.action.method.toLowerCase() == 'get' ? true : marks);
            else if ((action.member || type == 'member') && row) {
              menu_item.action.id = row.id
              menu_item.action.params['lock_version'] = row['_lock_version_']
              menu_item.action.label = row['_label_'];
            }
            else if (action.name == 'list') {
              menu_item.action.params = { '_nesting_': this.scaffold.nesting, '_pagination_': this.scaffold.pagination, '_searching_': this.scaffold.searching, '_sorting_': this.scaffold.sorting };
            }
            menus.push(menu_item);
          }
        }
        this.contextmenu.show(e.clientX, e.clientY, menus, row, () => { if (row) row._highlight_ = false });
        this.$util.clear_selection();
      },
      search: function(e) {
        this.$http.indicator = e;
        this.$http({
          url: this.scaffold.config.route_path,
          headers: { 'vs-id': this.vsid },
          params: {
            '_nesting_': this.scaffold.nesting,
            '_pagination_': { page: 1, size: this.scaffold.pagination.size },
            '_searching_': { columns: this.searching.columns, text: this.searching.text },
            '_sorting_': this.scaffold.sorting
          }
        }).catch((error) => {});
      },
      search_contextmenu: function(col, e) {
        return; // TODO
        this.$util.clear_selection();
        this.scaffold.config.columns[col]._highlight_ = true;
        let items = [];
        items.push({header: 'Column options', background: '#666'});
        items.push({label: 'Add filter', icon: 'fa-search-plus'});
        items.push({divider: true });
        items.push({label: 'Three'});
        this.contextmenu.show(e.clientX, e.clientY, items, col, () => { this.scaffold.config.columns[col]._highlight_ = false });
        this.$util.clear_selection();
      },
      search_operators: function(col) {
        var operators = [];
        if (['checkbox', 'select'].indexOf(this.scaffold.config.columns[col].search_ui) >= 0) {
          operators.push({ value: 'equals', label: this.$i18n.translate('scaffold', 'search.equals', 'Equals') });
          operators.push({ value: 'not_equals', label: this.$i18n.translate('scaffold', 'search.not_equals', 'Not equals') });
        } else if (['number', 'date', 'datetime', 'duration', 'time'].indexOf(this.scaffold.config.columns[col].search_ui) >= 0) {
          operators.push({ value: 'between', label: this.$i18n.translate('scaffold', 'search.between', 'Between') });
          operators.push({ value: 'eq', label: this.$i18n.translate('scaffold', 'search.eq', '=') });
          operators.push({ value: 'gt', label: this.$i18n.translate('scaffold', 'search.gt', '>') });
          operators.push({ value: 'gte', label: this.$i18n.translate('scaffold', 'search.gte', '>=') });
          operators.push({ value: 'lt', label: this.$i18n.translate('scaffold', 'search.lt', '<') });
          operators.push({ value: 'lte', label: this.$i18n.translate('scaffold', 'search.lte', '<=') });
        } else {
          operators.push({ value: 'contains', label: this.$i18n.translate('scaffold', 'search.contains', 'Contains') });
          operators.push({ value: 'not_contains', label: this.$i18n.translate('scaffold', 'search.not_contains', 'Not contains') });
          operators.push({ value: 'begins_with', label: this.$i18n.translate('scaffold', 'search.begins_with', 'Begins with') });
          operators.push({ value: 'ends_with', label: this.$i18n.translate('scaffold', 'search.ends_with', 'Ends with') });
          operators.push({ value: 'equals', label: this.$i18n.translate('scaffold', 'search.equals', 'Equals') });
        }
        if (!this.scaffold.config.columns[col].required) {
          operators.push({ value: 'is_null', label: this.$i18n.translate('scaffold', 'search.is_null', 'Is null') });
          operators.push({ value: 'is_not_null', label: this.$i18n.translate('scaffold', 'search.is_not_null', 'Is not null') });
        }
        return operators;
      },
      search_reset: function(refresh, e) {
        let operators = {};
        let search_columns = {};
        for (let col of this.scaffold.config.search.columns) {
          operators[col] = this.search_operators(col);
          search_columns[col] = { operator: operators[col][0].value, value1: '', value2: '' };
        }
        for (let col of this.scaffold.config.search.optional_columns) {
          operators[col] = this.search_operators(col);
          search_columns[col] = { operator: operators[col][0].value, value1: '', value2: '' };
        }
        this.searching.columns = search_columns;
        this.searching.operators = operators;
        this.searching.optional = false;
        if (refresh) this.search(e);
      },
      search_text_reset: function(refresh, e) {
        this.searching.text = '';
        if (refresh) this.search(e);
      },
      sort: function(col, e, multi_touch) {
        this.$util.clear_selection();
        if (multi_touch && e.touches.length <= 1) return;
        var sorting = {};
        if (e.shiftKey || multi_touch) {
          for (var key in this.scaffold.sorting) sorting[key] = this.scaffold.sorting[key];
          var order = this.scaffold.sorting['_order_'] || [];
          if (order.indexOf(col) < 0) order.push(col);
          sorting['_order_'] = order;
        } else sorting['_order_'] = [ col ];
        var sort_orders = this.scaffold.config.columns[col].sortable ? ['sort-down', 'sort-up'] : ['long-arrow-alt-down', 'long-arrow-alt-up'];
        if (typeof(this.sorting[col]) == 'undefined') this.sorting[col] = 0;
        sorting[col] = sort_orders[this.sorting[col]++];
        if (this.sorting[col] > sort_orders.length) this.sorting[col] = 0;
        if (!sorting[col]) {
          var index = sorting['_order_'].indexOf(col);
          if (index >= 0) sorting['_order_'].splice(index, 1);
        }
        this.scaffold.sorting = sorting;
        if (!this.scaffold.config.columns[col].sortable) return;
        this.$http.indicator = e;
        this.$http({
          url: this.scaffold.config.route_path,
          headers: { 'vs-id': this.vsid },
          params: {
            '_nesting_': this.scaffold.nesting,
            '_pagination_': { page: this.scaffold.pagination.page, size: this.scaffold.pagination.size },
            '_searching_': this.scaffold.searching,
            '_sorting_': this.scaffold.sorting
          }
        }).catch((error) => {
        });
      },
      sort_css_class: function(col) {
        if (this.scaffold.config.columns[col].sortable == null) return;
        let css_class = 'fa-';
        if (this.scaffold.sorting[col]) {
          let direction = this.scaffold.sorting[col];
          if (this.scaffold.config.columns[col].reverse_sort_sign) {
            if (direction == 'sort-up') direction = 'sort-down';
            else direction = 'sort-up';
          }
          css_class = css_class + direction;
        } else css_class = css_class + (this.scaffold.config.columns[col].sortable ? 'sort' : 'arrows-alt-v');
        if (this.scaffold.sorting[col] && this.scaffold.sorting[col].indexOf('arrow') > 0) return css_class;
        if (this.scaffold.sorting['_default_'] || !this.scaffold.sorting[col]) css_class += ' vs-dimmed';
        return css_class;
      },
      swipe_touchstart: function(e) {
        if (e.touches.length <= 1) return;
        this.swipe = { timestamp: new Date(), target: e.target, startX: e.touches[0].screenX, startY: e.touches[0].screenY }
        e.preventDefault();
      },
      swipe_touchmove: function(e) {
        if (e.touches.length <= 1) return;
        this.swipe.stopX = e.touches[0].screenX;
        this.swipe.stopY = e.touches[0].screenY;
        e.preventDefault();
      },
      swipe_touchend: function(e) {
        if (!this.swipe.stopX) return;
        var deltaX = this.swipe.stopX - this.swipe.startX;
        var deltaY = this.swipe.stopY - this.swipe.startY;
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          e.preventDefault();
          e.stopPropagation();
          if (deltaX < -20) {
            if (this.scaffold.pagination.page == 1) return;
            this.goto(this.scaffold.pagination.page - 1);
          } else if (deltaX > 20) {
            if (this.scaffold.pagination.total / this.scaffold.pagination.size <= this.scaffold.pagination.page) return;
            this.goto(this.scaffold.pagination.page + 1);
          }
        }
        this.swipe = {};
        e.preventDefault();
      },
      tooltip_start: function(tt, e) {
        if (this.tooltip.visible || this.tooltip.fetch) return;
        this.tooltip.fetch = true;
        if (tt) this.tooltip.timer = setTimeout(() => {
          if (!this.tooltip.fetch) return;
          if (tt.url) {
            if (this.$tooltips[tt.url]) this.tooltip_show(this.$tooltips[tt.url], e);
            else {
              this.$http.indicator = e;
              this.$http(tt.url).then((result) => {
                this.$tooltips[tt.url] = result.data;
                if (this.tooltip.fetch) this.tooltip_show(result.data, e);
              }).catch((error) => {});
            }
          }
        }, 250);
      },
      tooltip_stop: function(e) {
        clearTimeout(this.tooltip.timer);
        // if (e.clientX == this.tooltip.x && e.clientY == this.tooltip.y) return;
        this.tooltip.fetch = false;
        this.tooltip.text = null;
        this.tooltip.components = null;
        this.tooltip.visible = false;
      },
      tooltip_show: function(data, e) {
        if (data.components) this.tooltip.components = data.components;
        else if (data.text) this.tooltip.text = data.text;
        this.tooltip.x = e.clientX;
        this.tooltip.y = e.clientY;
        this.tooltip.visible = true;
      }
    },
  }
</script>
<style>
.vs-view {
  padding: 5px;
  position: relative;
  cursor: alias;
}
.vs-table-container {
  margin-top: 1px;
  overflow-x: auto;
}
.vs-table-container .vs-table-container {
  overflow-x: unset;
}
.vs-view .vs-even {
  background-color: #ffffbb;
}
.vs-view .vs-odd {
  background-color: #daffcd;
}
.vs-nesting > div {
  position: relative;
}
.vs-pagination {
  user-select: none;
}
.vs-pagination-bottom {
  margin-right: 1em;
}
.vs-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
}
.vs-table-header > div:last-child {
  border-bottom: 1px solid #ccc;
}
.vs-config,
.vs-search,
.vs-recyclebin {
  border: 1px solid #ccc;
  border-bottom: none;
  cursor: default;
}
.vs-search_operator {
  width: 9em !important;
}
.vs-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.vs-panel {
  cursor: default;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.vs-panel .field-body > span {
  padding-top: 0.375em;
}
@media screen and (min-width: 769px) {
  .vs-panel {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .vs-table-container {
  }
  .vs-panel {
  }
}
.vs-table-container > table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 0.25em;
  width: 100%;
}
.vs-search .control {
  width: 15em;
}
.vs-highlight {
  outline: 1px dashed #000;
  outline-offset: -1px;
}
.vs-highlight-light {
  outline: 1px dashed #eee;
  outline-offset: -1px;
}
.vs-table-container > table > tbody > tr {
  background-color: #fcfcfc;
}
.vs-table-container > table > tbody > tr.vs-marked,
.vs-even > .vs-table-container > table > tbody > tr.vs-marked,
.vs-odd > .vs-table-container > table > tbody > tr.vs-marked {
  background-color: #ececec;
}
.vs-table-container > table > tbody > tr:nth-child(odd) {
  background-color: #E6F2FF;
}
.vs-table-container > table > tbody > tr.vs-marked:nth-child(odd) {
  background-color: #b9cae2;
}
.vs-even > .vs-table-container > table > tbody > tr:nth-child(odd) {
  background-color: #fafae0;
}
.vs-even > .vs-table-container > table > tbody > tr.vs-marked:nth-child(odd) {
  background-color: #e5e5b7;
}
.vs-odd > .vs-table-container > table > tbody > tr:nth-child(odd) {
  background-color: #ECFFE7;
}
.vs-odd > .vs-table-container > table > tbody > tr.vs-marked:nth-child(odd) {
  background-color: #bddcb5;
}
.vs-header {
  display: flex;
  align-items: center;
}
.vs-view > .vs-header > h2 {
  margin: 0 0 0.25em 0;
}
th .vs-dimmed {
  color: #888;
}
th.vs-mark, th.vs-sortable {
  cursor: pointer;
}
tr.vs-table-header > th {
  background-color: #444;
  border-left: 1px solid #ccc;
  color: #fff;
  font-weight: normal;
  padding: 3px;
  white-space: nowrap;
  cursor: pointer;
}
.vs-table-container th > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vs-table-container th > div > label {
  flex: 1;
  margin: 0 2px;
}
tr.vs-table-header th:first-child {
  border-left: 1px solid #666;
}
tr.vs-table-header th:last-child {
  border-right: 1px solid #666;
}
.vs-empty td {
  background-color: #eee;
  text-align: center !important;
}
.vs-table-container > table > tbody > tr > td {
  border: 1px solid #ddd;
  border-width: 0 1px 1px 0;
  cursor: alias;
  padding: 3px;
}
.vs-table-container > table > tbody > tr > td:first-child {
  border-width: 0 1px 1px 1px;
}
td {
  cursor: default;
  vertical-align: middle !important;
}
td.vs-nesting {
  padding: 0 !important;
}
table tr > th.vs-datetime {
  width: 1px;
}
table tr > td.vs-datetime {
  text-align: center;
}
table tr > th.vs-checkbox,
table tr > th.vs-center {
  width: 1px;
  text-align: center;
  white-space: nowrap;
}
table tr > td.vs-checkbox,
table tr > td.vs-center {
  text-align: center;
  white-space: nowrap;
}
.vs-mark {
  width: 1px !important;
  text-align: center !important;
}
.vs-mark input[type=checkbox]:disabled {
  opacity: 0.75;
}
.vs-close {
  background-color: #40a71b;
  border: none;
  cursor: pointer;
  height: 1.65em;
  width: 1.65em;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  outline: 0;
  display: inline-block;
}
.vs-close:hover {
  background-color: #2d840e;
}
.vs-close::before,
.vs-close::after {
  background-color: #fff;
  content: "";
  display: block;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}
.vs-close::before {
  height: 2px;
  width: 50%;
}
.vs-close::after {
  height: 50%;
  width: 2px;
}
.vs-icon-event,
.vs-icon-event > i {
  color: #4a4a4a;
  cursor: pointer;
  pointer-events: auto !important;
}
.vs-pagination-size {
  border-radius: 0 !important;
}
.vs-sort-disabled {
  pointer-events: none;
  cursor: default;
}
.vs-tight { white-space: nowrap; width: 1px }
.vs-wrap { word-break: break-all }
.vs-text-width { max-width: 175px }
.vs-tooltip-target { color: teal; cursor: help }
::placeholder {
  opacity: 0.4;
}
</style>
