<template>
  <div v-if="options.vertical" class="field vs-input-string-vertical">
    <label class="label">{{ label }}</label>
    <span v-if="options.readonly">{{ value }}</span>
    <div v-else :class="options.icon ? 'has-icons-left' : ''" class="control">
      <label v-if="options.readonly">{{ value }}</label>
      <input v-else @blur="blur" @input="input" @keyup.enter="enter" :class="css_class" :disabled="options.disabled" :placeholder="options.placeholder" :value="value" ref="input" type="text">
      <span v-if="options.icon" class="icon is-small is-left">
        <i :class="options.icon" class="fas"></i>
      </span>
    </div>
  </div>
  <div v-else class="field is-horizontal vs-input-string">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <span v-if="options.readonly">{{ new Date(value).toLocaleString() }}</span>
      <div v-else class="field">
        <div class="control">
          <div :class="options.icon ? 'has-icons-left' : ''" class="control">
            <input @blur="blur" @input="input" @keyup.enter="enter" :class="css_class" :disabled="options.disabled" :placeholder="options.placeholder" :value="value" class="input" ref="input" type="text">
            <span v-if="options.icon" class="icon is-small is-left">
              <i :class="options.icon" class="fas"></i>
            </span>
          </div>
        </div>
        <div :class="{'has-text-danger': options.error}" class="help vs-description">{{ options.error || options.description }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: [ 'container', 'column', 'label', 'options', 'value' ],
    data: function () {
      return {
      }
    },
    mounted: function() {
      if (this.options.autofocus) this.$refs.input.focus();
    },
    computed: {
      css_class: function() {
        return 'input ' + (this.validated | this.options.disabled ? 'is-success' : 'is-danger');
      },
      validated: function() {
        return true;
      }
    },
    methods: {
      blur: function(e) {
        this.$emit('blur', e);
      },
      enter: function(e) {
        this.$emit('enter', e)
      },
      focus: function() {
        this.$refs.input.focus();
      },
      input: function(e) {
        this.$emit('input', e.target.value);
      }
    }
  }
</script>
