<template>
  <div v-if="options.vertical" class="field vs-input-editor-vertical">
    <label class="label">{{ label }}</label>
    <div :class="{ 'is-grouped': options.batch }" class="field">
      <div v-if="options.batch" class="control">
        <div class="select">
          <select v-model="batched" @change="$emit('batching', batched)">
            <option value="">{{ $i18n.translate('scaffold', 'batch.ignore', 'Ignore') }}</option>
            <option value="update">{{ $i18n.translate('scaffold', 'batch.update', 'Update') }}</option>
            <option value="nullify">{{ $i18n.translate('scaffold', 'batch.nullify', 'Nullify') }}</option>
          </select>
        </div>
      </div>
      <div class="control is-expanded">
        <label v-if="options.readonly">{{ value }}</label>
        <vs-editor @input="input" @validation="validation" :id="uuid" :value="value" :options="options" :disabled="options.disabled || (options.batch && batched != 'update')" ref="input"></vs-editor>
      </div>
    </div>
  </div>
  <div v-else class="field is-horizontal vs-input-editor">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <span v-if="options.readonly" class="vs-input-editor-readonly">{{ value }}</span>
      <div v-else :class="{ 'is-grouped': options.batch }" class="field">
        <div v-if="options.batch" class="control">
          <div class="select">
            <select v-model="batched" @change="$emit('batching', batched)">
              <option value="">{{ $i18n.translate('scaffold', 'batch.ignore', 'Ignore') }}</option>
              <option value="update">{{ $i18n.translate('scaffold', 'batch.update', 'Update') }}</option>
              <option v-if="!options.required" value="nullify">{{ $i18n.translate('scaffold', 'batch.nullify', 'Nullify') }}</option>
            </select>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="control">
            <vs-editor @input="input" @validation="validation" :id="uuid" :value="value" :options="options" :disabled="options.disabled || (options.batch && batched != 'update')" ref="input"></vs-editor>
          </div>
        </div>
        <div :class="{'has-text-danger': options.error}" class="help vs-description">{{ options.error || options.description }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import TinyMCE from '../../TinyMCE.vue'
  export default {
    components: {
      'vs-editor': TinyMCE
    },
    props: [ 'container', 'column', 'label', 'options', 'value' ],
    data: function () {
      return {
        batched: ''
      }
    },
    mounted: function() {
      if (this.options.autofocus) this.$refs.input.focus();
    },
    computed: {
      css_class: function() {
        // return 'input ' + (this.validated || this.options.disabled ? 'is-success' : 'is-danger');
      },
      validated: function() {
        var validating = true;
        if (this.options.validated) validating = this.options.validated;
        else if (this.options.email) validating = this.$util.test_email(this.value);
        else if (this.options.required) validating = (this.value != null);
        else if (this.options.minlength) validating = (this.value && this.value.length >= this.options.minlength);
        if (this.options.batch && this.batched != 'update') validating = true;
        this.$emit('validation', validating);
        return validating;
      },
      uuid: function() {
        return 'tinymce-' + this.container.model + '-' + this.column + '-' + this.container.id;
      }
    },
    methods: {
      input: function(value) {
        this.$emit('input', value);
      },
      validation: function(e) {
        this.$emit('validation', e);
      }
    }
  }
</script>
<style>
.vs-input-editor-readonly { word-break: break-all }
</style>
