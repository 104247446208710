<template>
  <div v-if="visible || timeout.visible" class="modal is-active" style="z-index: 999">
    <div class="modal-background"></div>
    <div class="modal-card vs-authentication">
      <header class="modal-card-head">
        <p v-if="timeout.visible" class="modal-card-title" v-html="$i18n.translate('security', 'texts.session_expiring_title', 'Session expiring soon')"></p>
        <p v-else-if="reset_password > 0 || update_password" class="is-size-5">{{ reset_password > 0 ? $i18n.translate('security', 'texts.reset_password', 'Reset your password') : $i18n.translate('security', 'texts.update_password', 'Update new password') }}</p>
        <img v-else src="../../images/security-icon.png">
        <button v-if="!update_password" @click="close" class="delete"></button>
      </header>
      <div class="modal-card-body">
        <div v-if="timeout.visible" v-html="$i18n.translate('security', 'texts.session_expiring_text', 'Your session will expire in %{timeout} seconds due to inactivity').replace('%{timeout}', timeout.interval)"></div>
        <template v-else-if="update_password">
          <vs-input v-model="new_password" @enter="check_inputs" @input="message=''" :label="$i18n.translate('security', 'labels.new_password', 'New password')" :options="{  minlength: password_minimum_length, placeholder: $i18n.translate('security', 'texts.required', 'Required'), icon: 'fa-key', required: true, vertical: true }" key="new_password" ref="new_password" ui="password">
          </vs-input>
          <vs-input v-model="new_password_confirmation" @enter="check_inputs" @input="message=''" :label="$i18n.translate('security', 'labels.new_password_confirmation', 'New password confirmation')" :options="{ placeholder: $i18n.translate('security', 'texts.required', 'Required'), minlength: password_minimum_length, equal: new_password, icon: 'fa-key', required: true, vertical: true }" key="new_password_confirmation" ref="new_password_confirmation" ui="password">
          </vs-input>
          <vs-input v-model="enabled_otp" @enter="check_inputs" :label="$i18n.translate('security', 'labels.enable_one_time_password', 'Enable one-time-password')" :options="{ login: login, token: otp, vertical: true }" ui="otp"></vs-input>
        </template>
        <template v-else>
          <vs-input v-model="login" @enter="check_inputs" @input="message=''" :label="$i18n.translate('security', 'labels.login', 'Login')" :options="{ disabled: reset_password > 0, minlength: 1, placeholder: $i18n.translate('security', 'texts.required', 'Required'), icon: 'fa-user', required: true, vertical: true }" key="login" ref="login">
          </vs-input>
          <vs-input v-if="reset_password > 0" @enter="check_inputs" @input="message=''" v-model="email" :label="$i18n.translate('security', 'labels.email', 'Email address')" :options="{ disabled: reset_password > 1, autofocus: true, placeholder: $i18n.translate('security', 'texts.required', 'Required'), icon: 'fa-at', email: true, required: true, vertical: true }" ref="email">
          </vs-input>
          <vs-input v-else @enter="check_inputs" @input="message=''" v-model="password" :label="$i18n.translate('security', 'labels.password', 'Password')" :options="{ placeholder: $i18n.translate('security', 'texts.required', 'Required'), icon: 'fa-lock', minlength: 1, vertical: true }" key="password" ref="password" ui="password">
          </vs-input>
          <vs-input v-if="reset_password > 1" v-model="reset_password_token" @enter="check_inputs" @input="message=''" :label="$i18n.translate('security', 'labels.reset_password_token', 'Reset password token')" :options="{ minlength: 1, placeholder: $i18n.translate('security', 'texts.required', 'Required'), icon: 'fa-key', required: true, vertical: true }" key="reset_password_token" ref="reset_password_token">
          </vs-input>
          <vs-input v-else-if="enabled_otp" v-model="otp" @enter="check_inputs" @input="message=''" :label="$i18n.translate('security', 'labels.one_time_password', 'One time password')" :options="{ minlength: enabled_otp ? 1 : 0, placeholder: enabled_otp ? $i18n.translate('security', 'texts.required', 'Required') : $i18n.translate('security', 'texts.optional', 'Optional'), icon: 'fa-user-lock', vertical: true }" key="otp" ref="otp">
          </vs-input>
        </template>
      </div>
      <footer class="modal-card-foot">
        <button v-if="timeout.visible" @click="extend_session" class="button is-link">{{ $i18n.translate('security', 'texts.session_extend', 'Extend session') }}</button>
        <span class="has-text-danger has-text-centered" :class="message == $i18n.translate('security', 'texts.reset_password_help', 'Reset password token has been sent to the given email address. (don\'t forget to check the SPAM folder)') ? 'help' : ''" v-html="message"></span>
        <a v-if="message == $i18n.translate('security', 'texts.login_failed', 'Invalid login or password') || message == $i18n.translate('security', 'texts.login_failed_otp', 'Invalid one time password')" @click="message = null; reset_password = 1" class="is-size-7">({{ $i18n.translate('security', 'texts.reset_password', 'Reset your password') }})</a>
        <span v-else-if="reset_password == 0 && !timeout.visible"><i class="fab fa-windows" style="color:#3273dc;margin-top:0.5em"></i> <a @click="sso_connect">{{ $i18n.translate('security', 'texts.sso_connect', 'Connect using Dhamma.org email') }}</a></span>
      </footer>
    </div>
  </div>
</template>

<script>
  import security_icon from '../../images/security-small.png'
  import VsInput from './VsInput.vue'
  export default {
    components: {
      'vs-input': VsInput
    },
    props: {
      data: {
        type: Object,
        default: function () { return {} }
      }
    },
    created: function() {
      this.sso.state = this.$util.generate_rotp_token();
      this.timeout.timer = setInterval(() => { this.check_timeout() }, 1000);
      window.addEventListener("message", (e) => {
        if (this.sso.win && e.source == this.sso.win) {
          if (e.data == 'disabled' + this.sso.state) {
            this.sso.prompt = true;
            this.message = this.$i18n.translate('security', 'texts.login_disabled', 'Account disabled!');
          } else if (e.data == 'noconsent' + this.sso.state) {
            this.sso.prompt = true;
            this.message = this.$i18n.translate('security', 'texts.sso_noconsent', 'Unable to connect without consent!');
          } else if (e.data == this.sso.state) {
            // this.$cable.connect();
            this.visible = false;
            if (this.retry && this.retry.target) this.retry.target.dispatchEvent(this.retry);
            else window.location.reload();
          }
          this.sso.win.close();
        }
      }, false);
      this.$http.interceptors.response.use((response) => {
        if (response.data._logout_) {
          this.$http.indicator = null;
          this.message = this.$i18n.translate('security', 'texts.session_ended', 'Session has ended. Please login again');
          this.urls.session = response.data.url;
          this.sso.url = response.data.sso;
          this.sso.prompt = false;
          this.sso.win = null;
          this.visible = true;
          this.timeout.enabled = false;
          this.$nextTick(() => {
            if (this.login == '') this.$refs.login.focus();
            else this.$refs.password.focus();
          });
        }
        if (response.headers['vs-session-login']) this.login = response.headers['vs-session-login'];
        if (response.headers['vs-session-timeout']) {
          this.timeout.expiry = new Date(new Date().getTime() + parseInt(response.headers['vs-session-timeout']) * 1000);
          this.timeout.enabled = true;
        }
        return response;
      }, (error) => {
        if (error.response && error.response.status == 403) {
          this.sso.prompt = false;
          this.sso.win = null;
          // forbidden error code
          if (error.response.headers['vs-session-url']) this.urls.session = error.response.headers['vs-session-url'];
          if (error.response.headers['vs-session-sso']) this.sso.url = error.response.headers['vs-session-sso'];
          if (error.response.headers['vs-session-reset-password']) this.urls.reset_password = error.response.headers['vs-session-reset-password'];
          // this.$cable.disconnect({ allowReconnect: false });
          this.visible = true;
          this.retry = this.$http.indicator;
          this.$nextTick(() => {
            if (this.login == '') this.$refs.login.focus();
            else {
              this.message = this.$i18n.translate('security', 'texts.session_expired', 'Session has expired. Please login again');
              this.$refs.password.focus();
            }
          });
        }
        return Promise.reject(error);
      });
    },
    data: function () {
      return {
        sso: {
          prompt: false,
          url: null,
          win: null,
          state: null
        },
        urls: {
          session: null,
          reset_password: null
        },
        retry: null,
        timeout: {
          enabled: false,
          expiry: null,
          interval: null,
          timer: null,
          title: null,
          visible: false
        },
        visible: false,
        email: '',
        enabled_otp: false,
        login: '',
        message: '',
        new_password: '',
        new_password_confirmation: '',
        otp: '',
        password: '',
        password_minimum_length: 0,
        reset_password: 0,
        reset_password_token: '',
        update_password: false
      }
    },
    methods: {
      check_timeout: function() {
        if (this.visible || !this.timeout.enabled) return;
        var ms = this.timeout.expiry - new Date();
        if (ms <= 0) {
          this.timeout.visible = false;
          this.timeout.enabled = false;
          if (this.timeout.title) {
            document.title = this.timeout.title;
            this.timeout.title = null;
          }
        } else if (ms <= 45000) {
          if (this.timeout.title == null) this.timeout.title = document.title;
          this.timeout.interval = Math.round(ms/1000);
          this.timeout.visible = true;
          if (!document.hasFocus()) {
            document.title = '(' + this.timeout.interval + ') ' + this.$i18n.translate('security', 'texts.session_expiring_title', 'Session expiring soon');
            this.$audio.play(220.00);
          } else if (this.timeout.title) document.title = this.timeout.title;
        }
      },
      close: function() {
        this.enabled_otp = false;
        this.message = '';
        this.otp = '';
        this.password = '';
        if (this.reset_password > 0) {
          this.email = '';
          this.reset_password = 0;
          this.reset_password_token = '';
          this.$nextTick(() => { this.check_inputs() });
        } else if (this.update_password) {
          this.email = '';
          this.reset_password_token = '';
          this.new_password = '';
          this.new_password_confirmation = '';
          this.update_password = false;
          this.$nextTick(() => { this.check_inputs() });
        } else if (this.timeout.visible) {
          this.timeout.visible = false;
          this.timeout.enabled = false;
        } else this.visible = false;
      },
      check_inputs: function(e) {
        if (this.update_password) {
          if (this.new_password.length < this.password_minimum_length) {
            this.message = this.$i18n.translate('security', 'texts.password_too_short', 'Password too short (minimum %{minimum} characters)').replace('%{minimum}', this.password_minimum_length.toString());
            this.$refs.new_password.focus();
          } else if (this.new_password != this.new_password_confirmation) {
            this.message = this.$i18n.translate('security', 'texts.password_matching', 'Password confirmation does not match');
            this.$refs.new_password_confirmation.focus();
          } else {
            this.password_update();
          }
        } else if (this.reset_password == 1) {
          if (!this.$util.test_email(this.email)) this.$refs.email.focus();
          else this.password_reset1();
        } else if (this.reset_password == 2) {
          if (this.reset_password_token == '') {
            this.$nextTick(() => { this.$refs.reset_password_token.focus() });
          } else this.password_reset2();
        } else {
          if (this.login == '') this.$refs.login.focus();
          else if (this.password == '') this.$refs.password.focus();
          else if (this.enabled_otp && this.otp == '') this.$refs.otp.focus();
          else this.sign_in();
        }
      },
      extend_session: function(e) {
        this.$http.indicator = e;
        this.$http({
          method: 'PUT',
          url: '/' + this.$i18n.locale + '/sessions/0'
        }).then((response) => {
          this.timeout.visible = false;
          if (this.timeout.title) document.title = this.timeout.title;
        }).catch((error) => {});
      },
      password_reset1: function(e) {
        if (!this.urls.reset_password) return;
        this.$http.indicator = e;
        this.$http({
          method: 'POST',
          url: this.urls.reset_password,
          data: { login: this.login, email: this.email }
        }).then((response) => {
          if (response.data.password_minimum_length) this.password_minimum_length = response.data.password_minimum_length;
          this.reset_password = 2;
          this.reset_password_token = '';
          this.message = this.$i18n.translate('security', 'texts.reset_password_help', 'Reset password token has been sent to the given email address. (don\'t forget to check the SPAM folder)');
          this.check_inputs();
        }).catch((error) => {
          this.message = this.$i18n.translate('security', 'texts.reset_password_failed', 'Invalid login or email address');
        });
      },
      password_reset2: function(e) {
        if (!this.urls.reset_password) return;
        this.$http.indicator = e;
        this.$http({
          method: 'POST',
          url: this.urls.reset_password,
          data: { login: this.login, email: this.email, reset_password_token: this.reset_password_token }
        }).then((response) => {
          if (response.data.password_minimum_length) this.password_minimum_length = response.data.password_minimum_length;
          this.otp = this.$util.generate_rotp_token();
          this.enabled_otp = false;
          this.reset_password = 0;
          this.update_password = true;
          this.message = '';
          this.$nextTick(() => { this.$refs.new_password.focus() });
        }).catch((error) => {
          if (error.response.status == 408) {
            this.message = this.$i18n.translate('security', 'texts.reset_password_token_expired', 'Reset password token expired. Please try again.');
          } else {
            this.message = this.$i18n.translate('security', 'texts.reset_password_token_failed', 'Invalid reset password token');
          }
        });
      },
      password_update: function(e) {
        if (!this.urls.reset_password) return;
        this.$http.indicator = e;
        this.$http({
          method: 'POST',
          url: this.urls.reset_password,
          data: { login: this.login, email: this.email, reset_password_token: this.reset_password_token, password: this.new_password, enabled_otp: this.enabled_otp, otp: this.otp }
        }).then((response) => {
          this.update_password = false;
          this.close();
          window.location.reload()
        }).catch((error) => {
          if (error.response.status == 409) this.message = this.$i18n.translate('security', 'texts.password_old', 'Cannot use old password');
          else {
            this.close();
            this.message = this.$i18n.translate('security', 'texts.reset_password_token_expired', 'Reset password token expired. Please try again.');
          }
        });
      },
      sign_in: function(e) {
        if (!this.urls.session) return;
        var data = { login: this.login, password: this.password };
        if (this.otp.length > 0) data['otp'] = this.otp;
        this.$http.indicator = e;
        this.$http({
          method: 'POST',
          url: this.urls.session,
          data: data
        }).then((response) => {
          this.enabled_otp = false;
          if (response.data.force_password_change) {
            this.otp = this.$util.generate_rotp_token();
            this.password_minimum_length = response.data.password_minimum_length;
            this.reset_password_token = response.data.reset_password_token;
            this.email = response.data.email;
            this.update_password = true;
            this.message = '';
            this.$nextTick(() => { this.$refs.new_password.focus() });
          } else {
            // this.$cable.connect();
            this.visible = false;
            if (this.retry && this.retry.target) this.retry.target.dispatchEvent(this.retry);
            else window.location.reload();
          }
        }).catch((error) => {
          if (error.response.status == 412) {
            this.enabled_otp = true;
            this.$nextTick(() => { this.$refs.otp.focus() });
          } else if (error.response.status == 406) {
            this.message = this.$i18n.translate('security', 'texts.login_disabled', 'Account disabled!');
          } else if (error.response.status == 423) {
            this.message = this.$i18n.translate('security', 'texts.login_locked', 'Too many failed attempts.<br>Account locked for 5 minutes!');
          } else if (error.response.status == 424) {
            this.message = this.$i18n.translate('security', 'texts.login_failed_otp', 'Invalid one time password');
          } else {
            this.message = this.$i18n.translate('security', 'texts.login_failed', 'Invalid login or password');
          }
        });
      },
      sso_connect: function(e) {
        this.sso.state = this.$util.generate_rotp_token();
        let url = this.sso.url + '&state=' + this.sso.state;
        if (this.sso.prompt) url = url + '&prompt=login';
        const h = 600;
        const w = 500;
        const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
        this.sso.win = window.open(url, 'sso_connect', 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);
      }
    }
  }
</script>
<style>
.vs-authentication {
  max-width: 22em;
}
.vs-authentication a:hover {
  color: #3273dc;
  text-decoration: underline;
}
.vs-authentication > header,
.vs-authentication > footer {
  justify-content: center;
  min-height: 4.5em;
  padding: 0 20px;
}
.vs-authentication > footer,
.vs-authentication > footer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vs-authentication > header > img {
  width: 3em;
}
.vs-authentication > header > button {
  position: absolute;
  right: 20px;
}
</style>
