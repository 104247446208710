<template>
  <li class="vs-help-item" :class="{ 'active': is_active }">
    <a @click.prevent.stop="fetch(false, $event)">
      <span v-if="data.icon" class="icon"><i class="fas" :class="data.icon"></i></span>
      <span class="vs-help-item-text noselection">{{ data.title }}</span>
      <span @click.prevent.stop="fetch(true, $event)" v-if="data.items" class="icon"><i class="fas fa-caret-down"></i></span>
    </a>
    <ul v-show="is_nesting" v-if="data.items">
      <vs-help-item @show="show" v-for="(item,index) in data.items" :active="active" :data="item" :key="'item_' + index"></vs-help-item>
    </ul>
  </li>
</template>
<script>
  export default {
    name: 'vs-help-item',
    props: {
      active: {
        type: Object,
        default: function () { return {} }
      },
      data: {
        type: Object,
        default: function () { return {} }
      }
    },
    data: function () {
      return {
        result: null
      }
    },
    computed: {
      is_active: function() {
        if (this.data.id) return this.active[this.data.id];
        return false;
      },
      is_nesting: function() {
        if (this.data.id) return (this.data.id in this.active);
        return false;
      }
    },
    methods: {
      fetch: function(caret, e) {
        this.$http.indicator = e;
        let fetcher = this.$http;
        let shown = false;
        if (this.result) {
          this.$emit('show', this.result, caret);
          shown = true;
          fetcher = this.$axios;
        }
        fetcher('/helps/' + this.data.id + '/fetch').then((result) => {
          this.result = result.data;
          if (this.is_active || !shown) this.$emit('show', this.result, caret);
        }).catch((error) => {});
      },
      show: function(result, caret) {
        this.$emit('show', result, caret);
      }
    }
  }
</script>
<style>
  .vs-help-aside ul {
    padding-left: 0;
  }
  .vs-help-aside ul ul {
    border-left: 0.75em solid #ededed;
  }
  .vs-help-aside li > a {
    align-items: center;
    border-bottom: 1px solid #ededed;
    color: #4a4a4a;
    display: flex;
    padding: 0.5em;
  }
  .vs-help-aside li > a:hover {
    color: #3273dc;
  }
  .vs-help .active > a {
    background-color: #f8f8f8;
  }
  .vs-help-item-text {
    margin-left: 0.5em;
    flex: 1 1 auto;
  }
</style>
