<template>
  <div class="vs-panel-view" @dblclick.stop="" @contextmenu.stop="">
    <div class="vs-panel-header">
      <h2 class="title is-4">{{ data.label }}</h2>
      <button class="vs-close" @click="$emit('close')"></button>
    </div>
    <div class="vs-panel-container">
      <div v-if="groups.length > 1" class="tabs is-boxed is-medium">
        <ul>
          <template v-for="(group,tindex) in groups">
            <li :class="tindex == tab ? 'is-active' :''" :key="'tab_' + tindex">
              <a @click="tab = tindex">{{ (group.label || $i18n.translate('scaffold', 'texts.general')).toUpperCase() }}</a>
            </li>
          </template>
        </ul>
      </div>
      <template v-for="(group,gindex) in groups">
        <div v-show="gindex == tab" class="vs-panel-group" :key="'group_' + gindex">
          <template v-for="(component,index) in group.components">
            <vs-input v-if="component.ui" v-model="component.value" @batching="component.batched = $event" @validation="component.validated = $event" :ui="component.ui" :column="component.column" :options="component.options" :label="component.label" :key="'component_' + index" :container="data" :ref="component.column"></vs-input>
          </template>
        </div>
      </template>
    </div>
    <div class="vs-buttons">
      <button v-for="(button,index) in buttons" :key="'button_' + index" :class="css_class(button)" class="button" @click="click(button, $event)" :disabled="disabled(button)" v-html="button.label"></button>
    </div>
  </div>
</template>

<script>
  import VsInput from './VsInput.vue'
  export default {
    components: {
      'vs-input': VsInput
    },
    props: {
      data: {
        type: Object,
        default: function () { return {} }
      },
      marks: {
        type: Object,
        default: function () { return {} }
      },
      vsid: {
        type: String,
        default: function () { return '' }
      }
    },
    data: function () {
      return {
        tab: 0
      }
    },
    computed: {
      buttons: function() {
        if (this.data.buttons) return this.data.buttons;
        return [ { label: this.$i18n.translate('scaffold', 'buttons.close', 'Close'), cancel: true } ];
      },
      groups: function() {
        let groups = [];
        let columns_components = {};
        for (let c of this.data.components) columns_components[c.column] = c;
        for (let g of this.data.groups) {
          g.components = [];
          for (let c of g.columns) {
            if (c in columns_components) g.components.push(columns_components[c]);
            delete columns_components[c];
          }
          groups.push(g);
        }
        let default_group = { components: [] };
        for (let comp of this.data.components) if (comp.column in columns_components) default_group.components.push(comp);
        if (default_group.components.length > 0) groups.unshift(default_group);
        return groups;
      }
    },
    methods: {
      click: function(button, e) {
        if (button.primary) {
          this.$http.indicator = e;
          let params = button.params || {};
          for (let comp of this.data.components) {
            if (comp.options.disabled) continue;
            let value = comp.value;
            if (value == undefined) value = null;
            if (this.data.batch == undefined || comp.batched != '') params[comp.options.column || comp.column] = value;
            comp.options.error = null;
          }
          if (this.data.lock_version) params['lock_version'] = this.data.lock_version;
          if (this.data.batch) params['_batch_'] = Object.values(this.marks);
          let options = { method: button.method || 'GET', url: button.url, headers: { 'vs-id': this.vsid } };
          if (options.method.toLowerCase() == 'get') options['params'] = params;
          else options['data'] = params;
          this.$http(options).then((result) => {
            this.$emit('close');
          }).catch((error) => {
            if (error.response.data.conflicts) {
              for (var col in error.response.data.conflicts) {
                if (col == 'lock_version') {
                  this.data.lock_version = error.response.data.conflicts[col];
                  continue;
                }
                for (let comp of this.data.components) {
                  if ((comp.column == col || comp.options.column == col) && comp.value != error.response.data.conflicts[col]) {
                    comp.options.input = true;
                    comp.options.error = this.$i18n.translate('scaffold', 'texts.stale_record', 'Record value was modified') + ': ' + this.$util.truncate(error.response.data.conflicts[col].toString(), 75);
                    break;
                  }
                }
              }
            }
            if (error.response.data.errors) {
              for (var col in error.response.data.errors) {
                for (let comp of this.data.components) {
                  if (comp.column == col || comp.options.column == col) {
                    comp.options.input = true;
                    comp.options.error = error.response.data.errors[col];
                    break;
                  }
                }
              }
            }
          });
        } else if (button.cancel) this.$emit('close');
      },
      css_class: function(button) {
        if (button.primary && this.data.components.every((comp) => { return comp.validated }) && !this.data.components.every((comp) => { return comp.batched == '' })) return 'is-link';
      },
      disabled: function(button) {
        if (!button.primary) return false;
        return (!this.data.components.every((comp) => { return comp.validated }) || this.data.components.every((comp) => { return comp.batched == '' }));
      },
      confirm: function(e) {
        this.data.confirm.submit(e).then((result) => {
          this.$emit('close');
        }).catch((result) => {});
      }
    },
  }
</script>
<style>
.vs-panel-view {
  padding: 5px;
  position: relative;
  cursor: default;
}
.vs-panel-container {
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.vs-panel-container .tabs.is-boxed li.is-active a {
  background-color: transparent;
  border: none;
}
.vs-panel-container .tabs.is-boxed a:hover {
  background-color: transparent;
}
@media screen and (min-width: 769px) {
  .vs-panel-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.vs-buttons > button {
  margin-right: 0.5em;
}
</style>
