<template>
  <div v-if="options.vertical" :class="{'is-grouped': options.batch}" class="field vs-input-checkbox-vertical">
    <div v-if="options.batch && !options.readonly" class="control">
      <div class="select">
        <select v-model="batched" @change="$emit('batching', batched)">
          <option value="">{{ $i18n.translate('scaffold', 'batch.ignore', 'Ignore') }}</option>
          <option value="update">{{ $i18n.translate('scaffold', 'batch.update', 'Update') }}</option>
          <option v-if="!options.required" value="nullify">{{ $i18n.translate('scaffold', 'batch.nullify', 'Nullify') }}</option>
        </select>
      </div>
    </div>
    <div class="control vs-checkbox">
      <label>
        <input v-if="options.readonly" :checked="value" disabled type="checkbox">
        <input v-else @blur="blur" @input="input" @keyup.enter="enter" :checked="value" :disabled="options.disabled || (options.batch && batched != 'update')" ref="input" type="checkbox">
        {{ label }}
      </label>
    </div>
  </div>
  <div v-else class="field is-horizontal vs-input-checkbox">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div v-if="options.readonly" class="control vs-checkbox">
        <label>
          <input :checked="value" disabled type="checkbox">
        </label>
      </div>
      <div v-else class="field is-grouped">
        <div v-if="options.batch" class="control">
          <div class="select">
            <select v-model="batched" @change="$emit('batching', batched)">
              <option value="">{{ $i18n.translate('scaffold', 'batch.ignore', 'Ignore') }}</option>
              <option value="update">{{ $i18n.translate('scaffold', 'batch.update', 'Update') }}</option>
              <option v-if="!options.required" value="nullify">{{ $i18n.translate('scaffold', 'batch.nullify', 'Nullify') }}</option>
            </select>
          </div>
        </div>
        <div class="control vs-checkbox">
          <label>
            <input @blur="blur" @input="input" @keyup.enter="enter" :checked="value" :disabled="options.disabled || (options.batch && batched != 'update')" ref="input" type="checkbox">
          </label>
        </div>
        <div :class="{'has-text-danger': options.error}" class="help vs-checkbox vs-description">{{ options.error || options.description }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: [ 'container', 'column', 'label', 'options', 'value' ],
    data: function () {
      return {
        batched: ''
      }
    },
    created: function() {
      if (typeof this.options.validation == 'undefined') this.options.validation = {};
    },
    mounted: function() {
      if (this.options.autofocus) this.$refs.input.focus();
    },
    methods: {
      blur: function(e) {
        this.$emit('blur', e);
      },
      enter: function(e) {
        this.$emit('enter', e)
      },
      focus: function() {
        this.$refs.input.focus();
      },
      input: function(e) {
        this.$emit('input', e.target.checked || false);
      }
    }
  }
</script>
<style>
.vs-checkbox {
  display: flex;
  align-items: center;
  margin-left: 0.25em;
}
.vs-input-checkbox input[type=checkbox] {
  transform: scale(1.4);
  vertical-align: middle;
}
.vs-input-checkbox > label {
  display: flex;
}
.vs-input-checkbox > label > input {
  margin-right: 0.5em;
}
</style>
