<template>
  <div @input="input" v-html="value" :id="uuid" class="tinymce-editor" ref="input"></div>
</template>

<script>
  import tinymce from 'tinymce/tinymce';
  import 'tinymce/themes/mobile';
  import 'tinymce/themes/silver';
  import 'tinymce/skins/ui/oxide/fonts/tinymce-mobile.woff';
  import 'tinymce/skins/ui/oxide/content.min.css';
  import 'tinymce/skins/ui/oxide/skin.min.css';
  import 'tinymce/plugins/advlist';
  import 'tinymce/plugins/autolink';
  import 'tinymce/plugins/autoresize';
  import 'tinymce/plugins/autosave';
  import 'tinymce/plugins/charmap';
  import 'tinymce/plugins/code';
  import 'tinymce/plugins/codesample';
  import 'tinymce/plugins/colorpicker';
  import 'tinymce/plugins/contextmenu';
  import 'tinymce/plugins/directionality';
  import 'tinymce/plugins/fullscreen';
  import 'tinymce/plugins/help';
  import 'tinymce/plugins/hr';
  import 'tinymce/plugins/image';
  import 'tinymce/plugins/imagetools';
  import 'tinymce/plugins/legacyoutput';
  import 'tinymce/plugins/link';
  import 'tinymce/plugins/lists';
  import 'tinymce/plugins/media';
  import 'tinymce/plugins/nonbreaking';
  import 'tinymce/plugins/noneditable';
  import 'tinymce/plugins/pagebreak';
  import 'tinymce/plugins/paste';
  import 'tinymce/plugins/preview';
  import 'tinymce/plugins/print';
  import 'tinymce/plugins/quickbars';
  import 'tinymce/plugins/save';
  import 'tinymce/plugins/searchreplace';
  import 'tinymce/plugins/spellchecker';
  import 'tinymce/plugins/table';
  import 'tinymce/plugins/template';
  import 'tinymce/plugins/textcolor';
  import 'tinymce/plugins/textpattern';
  import 'tinymce/plugins/toc';
  import 'tinymce/plugins/visualblocks';
  import 'tinymce/plugins/visualchars';
  import 'tinymce/plugins/wordcount';
  export default {
    props: ['disabled', 'id', 'options', 'value'],
    destroyed: function() {
      this.editor.remove();
    },
    mounted: function() {
      let options = {
        target: this.$refs.input,
        plugins: ["advlist", "autolink", "autoresize", "autosave", "charmap", "code", "codesample", "directionality", "fullscreen", "help", "hr", "image", "imagetools", "link", "lists", "media", "nonbreaking", "noneditable", "pagebreak", "paste", "preview", "searchreplace", "spellchecker", "table", "template", "visualblocks", "visualchars", "wordcount"],
        branding: false,
        menubar: false,
        toolbar_groups: {
          aligning: {
            icon: 'align-justify',
            tooltip: 'Alignment',
            items: 'alignleft aligncenter alignright alignjustify | alignnone'
          },
          blocks: {
            text: 'Blocks',
            items: 'p h1 h2 h3 h4 h5 h6 pre blockquote div'
          },
          formatting: {
            icon: 'format',
            tooltip: 'Formatting',
            items: 'bold italic underline | strikethrough superscript subscript | forecolor backcolor | removeformat'
          },
          indenting: {
            icon: 'indent',
            tooltip: 'Indentation',
            items: 'bullist numlist | indent outdent'
          }
        },
        block_formats: 'Title 1=h1; Title 2=h2; Title 3=h3; Title 4=h4; Title 5=h5; Paragraph=p; Preformatted=pre; Blockquote=blockquote',
        toolbar: 'undo redo | formatselect formatting aligning indenting | link charmap image media | hr codesample table | visualblocks code | fullscreen',
        // quickbars_insert_toolbar: 'quicktable image media codesample',
        // quickbars_insert_toolbar: '',
        // quickbars_selection_toolbar: 'bold italic underline | formatselect | blockquote quicklink',
        // contextmenu: 'undo redo | link image imagetools inserttable | cell row column deletetable | help',
        // content_css: 'https://cdnjs.cloudflare.com/ajax/libs/bulma/0.8.1/css/bulma.min.css',
        // content_css: 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css',
        //inline: true,
        skin_url: 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.2.1/skins/ui/oxide',
        content_css: ['https://cdnjs.cloudflare.com/ajax/libs/bulma/0.8.1/css/bulma.min.css', 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.2.1/skins/ui/oxide/content.min.css'],
        content_style: 'html { overflow-x: hidden; overflow-y: auto }',
        contextmenu: false,
        formats: {
          h1: { block: 'h1', attributes: { class: 'title is-2' } },
          h2: { block: 'h2', attributes: { class: 'title is-3' } },
          h3: { block: 'h3', attributes: { class: 'title is-4' } },
          h4: { block: 'h4', attributes: { class: 'title is-5' } },
          h5: { block: 'h5', attributes: { class: 'title is-6' } },
        },
        auto_resize: true,
        body_class: 'content',
        br_in_pre: false,
        default_link_target: "_blank",
        paste_data_images: true,
        fixed_toolbar_container: '#mce-toolbar',
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        table_style_by_css: true,
        table_default_attributes: { class: 'table is-bordered' },
        visual: true,
        file_picker_callback: function (callback, value, meta) {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.onchange = function () {
            var file = this.files[0];
            var reader = new FileReader();
            reader.onload = function () {
              var id = 'blobid' + (new Date()).getTime();
              var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(',')[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              callback(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        },
        setup: (editor) => {
          editor.on('change', (e) => {
            this.$emit('input', editor.getContent());
          });
          editor.on('PostProcess', function(e) {
            e.content = e.content.replace(/\<br \/\>/g, "<br />\n");
          });
        }        
      }
      tinymce.init(options).then((editors) => { this.editor = editors[0] });
    },
    data: function () {
      return {
        editor: null
      }
    },
    watch: {
    },
    computed: {
      uuid: function() {
        if (this.id) return this.id;
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
        return 'tinymce-' + uuid;
      }
    },
    methods: {
      input: function(value) {
        this.$emit('input', value);
      }
    }
  }
</script>
<style>
.tinymce-editor {
  background-color: white;
  border: 1px solid #ccc;
  min-height: 200px;
}
</style>
