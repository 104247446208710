<template>
  <div>{{ duration }}</div>
</template>
<script>
  export default {
    props: [ 'data', 'options' ],
    computed: {
      duration: function() {
        return this.$util.format_time_diff(new Date(), new Date(this.data.value));
      }
    }
  }
</script>
